import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    createTheme,
    ThemeProvider as BaseThemeProvider
} from '@mui/material';
import { createTheme as createThemeV4, ThemeProvider as BaseThemeV4Provider } from "@material-ui/core";
import { uniqBy } from "lodash";
import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { setTheme } from "../redux/themeSlice";
import { ThemeObject } from "../../MaterialTripList/objects/themeObject";
import { AppState } from "../../../../Reducers/Reducers";
import GothamLight from "../../../../Fonts/Gotham/Gotham-Light.otf";
import GothamExtraLight from "../../../../Fonts/Gotham/Gotham-ExtraLight.otf";
import GothamMedium from "../../../../Fonts/Gotham/Gotham-Medium.otf";
import GothamLightItalic from "../../../../Fonts/Gotham/Gotham-LightItalic.otf";
import GothamExtraLightItalic from "../../../../Fonts/Gotham/Gotham-ThinItalic.otf";
import GothamMediumItalic from "../../../../Fonts/Gotham/Gotham-MediumItalic.otf";
import SimpleNails from "../../../../Fonts/Simplesnails ver 4.0.ttf";

type Props = {
    children: React.ReactNode
}

export function ThemeProvider(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const themeObject = useSelector((state: AppState) => state.theme.theme);
    const theme = useMemo(() => {
        const themeOptions = themeObject?.data;
        let pickerText: any;

        if (i18n.language.includes('fr')) {
            pickerText = require('@mui/x-date-pickers/locales/frFR').frFR;
        } else if (i18n.language.includes('en')) {
            pickerText = require('@mui/x-date-pickers/locales/enUS').enUS;
        } else if (i18n.language.includes('es')) {
            pickerText = require('@mui/x-date-pickers/locales/esES').esES;
        }

        return createTheme(
            {
                components: {
                    MuiCssBaseline: {
                        styleOverrides: `
                        @font-face {
                            font-family: Gotham;
                            font-style: normal;
                            font-weight: 100;
                            src: url('${GothamExtraLight}')  format('opentype');
                        }
                        @font-face {
                            font-family: Gotham;
                            font-style: normal;
                            font-weight: 400;
                            src: url('${GothamLight}')  format('opentype');
                        }
                        @font-face {
                            font-family: Gotham;
                            font-style: normal;
                            font-weight: 500;
                            src: url('${GothamMedium}')  format('opentype');
                        }


                        @font-face {
                            font-family: Gotham;
                            font-style: italic;
                            font-weight: 100;
                            src: url('${GothamExtraLightItalic}')  format('opentype');
                        }
                        @font-face {
                            font-family: Gotham;
                            font-style: italic;
                            font-weight: 400;
                            src: url('${GothamLightItalic}')  format('opentype');
                        }
                        @font-face {
                            font-family: Gotham;
                            font-style: italic;
                            font-weight: 500;
                            src: url('${GothamMediumItalic}')  format('opentype');
                        }
                        @font-face {
                            font-family: SimpleNails;
                            src: url('${SimpleNails}')  format('truetype');
                        }
                    `
                    },
                    MuiButtonBase: {
                        styleOverrides: {
                            root: {
                                fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                            }
                        }
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                            }
                        }
                    },
                    MuiTab: {
                        styleOverrides: {
                            root: {
                                fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                            }
                        }
                    }
                },
                palette: {
                    primary: {
                        main: themeOptions?.colors?.primary ?? '#B7985A',
                        contrastText: '#fff'
                    },
                    secondary: {
                        main: themeOptions?.colors?.secondary ?? '#003B6F',
                        contrastText: '#fff'
                    }
                },
                buttons: {
                    confirm: {
                        variant: (themeOptions?.buttons?.confirm.variant ?? 'contained') as any,
                        borderRadius: themeOptions?.buttons?.confirm.radius ?? 4
                    },
                    cancel: {
                        variant: (themeOptions?.buttons?.cancel.variant ?? 'outlined') as any,
                        borderRadius: themeOptions?.buttons?.cancel.radius ?? 4
                    },
                    header: {
                        variant: (themeOptions?.buttons?.header.variant ?? 'text') as any,
                        borderRadius: themeOptions?.buttons?.header.radius ?? 4
                    },
                },
                inputs: themeOptions?.inputs ? {
                    texts: {
                        borderRadius: themeOptions?.inputs.textFields.radius,
                        variant: themeOptions?.inputs.textFields.variant
                    },
                    selectors: {
                        borderRadius: themeOptions?.inputs.selectors.radius,
                        variant: themeOptions?.inputs.selectors.variant
                    }
                } :
                    undefined,
                typography: {
                    h1: {
                        fontSize: themeOptions?.typography?.h1.fontSize ?? '6rem',
                        fontFamily: themeOptions?.typography?.h1.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h1.fontWeight ?? 300,
                        color: themeOptions?.typography?.h1.color,
                        textDecoration: themeOptions?.typography?.h1.textDecoration ?? 'none'
                    },
                    h2: {
                        fontSize: themeOptions?.typography?.h2.fontSize ?? '3.75rem',
                        fontFamily: themeOptions?.typography?.h2.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h2.fontWeight ?? 300,
                        color: themeOptions?.typography?.h2.color,
                        textDecoration: themeOptions?.typography?.h2.textDecoration ?? 'none'
                    },
                    h3: {
                        fontSize: themeOptions?.typography?.h3.fontSize ?? '3rem',
                        fontFamily: themeOptions?.typography?.h3.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h3.fontWeight ?? 700,
                        color: themeOptions?.typography?.h3.color,
                        textDecoration: themeOptions?.typography?.h3.textDecoration ?? 'none'
                    },
                    h4: {
                        fontSize: themeOptions?.typography?.h4.fontSize ?? '2.125rem',
                        fontFamily: themeOptions?.typography?.h4.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h4.fontWeight ?? 700,
                        color: themeOptions?.typography?.h4.color,
                        textDecoration: themeOptions?.typography?.h4.textDecoration ?? 'none'
                    },
                    h5: {
                        fontSize: themeOptions?.typography?.h5.fontSize ?? '1.5rem',
                        fontFamily: themeOptions?.typography?.h5.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h5.fontWeight ?? 700,
                        color: themeOptions?.typography?.h5.color,
                        textDecoration: themeOptions?.typography?.h5.textDecoration ?? 'none'
                    },
                    h6: {
                        fontSize: themeOptions?.typography?.h6.fontSize ?? '1.25rem',
                        fontFamily: themeOptions?.typography?.h6.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h6.fontWeight ?? 700,
                        color: themeOptions?.typography?.h6.color,
                        textDecoration: themeOptions?.typography?.h6.textDecoration ?? 'none'
                    },
                    subtitle1: {
                        fontSize: themeOptions?.typography?.subtitle1.fontSize ?? '1rem',
                        fontFamily: themeOptions?.typography?.subtitle1.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.h1.fontWeight ?? 400,
                        color: themeOptions?.typography?.subtitle1.color,
                        textDecoration: themeOptions?.typography?.subtitle1.textDecoration ?? 'none'
                    },
                    subtitle2: {
                        fontSize: themeOptions?.typography?.subtitle2.fontSize ?? '0.875rem',
                        fontFamily: themeOptions?.typography?.subtitle2.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.subtitle2.fontWeight ?? 400,
                        color: themeOptions?.typography?.subtitle2.color,
                        textDecoration: themeOptions?.typography?.subtitle2.textDecoration ?? 'none'
                    },
                    body1: {
                        fontSize: themeOptions?.typography?.body1.fontSize ?? '1rem',
                        fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.body1.fontWeight ?? 400,
                        color: themeOptions?.typography?.body1.color,
                        textDecoration: themeOptions?.typography?.body1.textDecoration ?? 'none'
                    },
                    body2: {
                        fontSize: themeOptions?.typography?.body2.fontSize ?? '0.875rem',
                        fontFamily: themeOptions?.typography?.body2.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.body2.fontWeight ?? 400,
                        color: themeOptions?.typography?.body2.color,
                        textDecoration: themeOptions?.typography?.body2.textDecoration ?? 'none'
                    },
                    overline: {
                        fontSize: themeOptions?.typography?.overline.fontSize ?? '0.75rem',
                        fontFamily: themeOptions?.typography?.overline.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.overline.fontWeight ?? 400,
                        color: themeOptions?.typography?.overline.color,
                        textDecoration: themeOptions?.typography?.overline.textDecoration ?? 'none'
                    },
                    caption: {
                        fontSize: themeOptions?.typography?.caption.fontSize ?? '0.75rem',
                        fontFamily: themeOptions?.typography?.caption.fontFamily ?? 'Gotham',
                        fontWeight: themeOptions?.typography?.caption.fontWeight ?? 400,
                        color: themeOptions?.typography?.caption.color,
                        textDecoration: themeOptions?.typography?.caption.textDecoration ?? 'none'
                    }
                }
            },
            pickerText
        );
    }, [themeObject, i18n]);
    const themeV4 = useMemo(() => {
        const themeOptions = themeObject?.data;
        return createThemeV4({
            overrides: {
                MuiButtonBase: {
                    root: {
                        fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                    }
                },
                MuiButton: {
                    root: {
                        fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                    }
                },
                MuiTab: {
                    root: {
                        fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham'
                    }
                }
            },
            palette: {
                primary: {
                    main: themeOptions?.colors?.primary ?? '#B7985A',
                    contrastText: '#fff'
                },
                secondary: {
                    main: themeOptions?.colors?.secondary ?? '#003B6F',
                    contrastText: '#fff'
                }
            },
            typography: {
                h1: {
                    fontSize: themeOptions?.typography?.h1.fontSize ?? '6rem',
                    fontFamily: themeOptions?.typography?.h1.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h1.fontWeight ?? 300,
                    color: themeOptions?.typography?.h1.color,
                    textDecoration: themeOptions?.typography?.h1.textDecoration ?? 'none'
                },
                h2: {
                    fontSize: themeOptions?.typography?.h2.fontSize ?? '3.75rem',
                    fontFamily: themeOptions?.typography?.h2.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h2.fontWeight ?? 300,
                    color: themeOptions?.typography?.h2.color,
                    textDecoration: themeOptions?.typography?.h2.textDecoration ?? 'none'
                },
                h3: {
                    fontSize: themeOptions?.typography?.h3.fontSize ?? '3rem',
                    fontFamily: themeOptions?.typography?.h3.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h3.fontWeight ?? 700,
                    color: themeOptions?.typography?.h3.color,
                    textDecoration: themeOptions?.typography?.h3.textDecoration ?? 'none'
                },
                h4: {
                    fontSize: themeOptions?.typography?.h4.fontSize ?? '2.125rem',
                    fontFamily: themeOptions?.typography?.h4.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h4.fontWeight ?? 700,
                    color: themeOptions?.typography?.h4.color,
                    textDecoration: themeOptions?.typography?.h4.textDecoration ?? 'none'
                },
                h5: {
                    fontSize: themeOptions?.typography?.h5.fontSize ?? '1.5rem',
                    fontFamily: themeOptions?.typography?.h5.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h5.fontWeight ?? 700,
                    color: themeOptions?.typography?.h5.color,
                    textDecoration: themeOptions?.typography?.h5.textDecoration ?? 'none'
                },
                h6: {
                    fontSize: themeOptions?.typography?.h6.fontSize ?? '1.25rem',
                    fontFamily: themeOptions?.typography?.h6.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h6.fontWeight ?? 700,
                    color: themeOptions?.typography?.h6.color,
                    textDecoration: themeOptions?.typography?.h6.textDecoration ?? 'none'
                },
                subtitle1: {
                    fontSize: themeOptions?.typography?.subtitle1.fontSize ?? '1rem',
                    fontFamily: themeOptions?.typography?.subtitle1.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.h1.fontWeight ?? 400,
                    color: themeOptions?.typography?.subtitle1.color,
                    textDecoration: themeOptions?.typography?.subtitle1.textDecoration ?? 'none'
                },
                subtitle2: {
                    fontSize: themeOptions?.typography?.subtitle2.fontSize ?? '0.875rem',
                    fontFamily: themeOptions?.typography?.subtitle2.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.subtitle2.fontWeight ?? 400,
                    color: themeOptions?.typography?.subtitle2.color,
                    textDecoration: themeOptions?.typography?.subtitle2.textDecoration ?? 'none'
                },
                body1: {
                    fontSize: themeOptions?.typography?.body1.fontSize ?? '1rem',
                    fontFamily: themeOptions?.typography?.body1.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.body1.fontWeight ?? 400,
                    color: themeOptions?.typography?.body1.color,
                    textDecoration: themeOptions?.typography?.body1.textDecoration ?? 'none'
                },
                body2: {
                    fontSize: themeOptions?.typography?.body2.fontSize ?? '0.875rem',
                    fontFamily: themeOptions?.typography?.body2.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.body2.fontWeight ?? 400,
                    color: themeOptions?.typography?.body2.color,
                    textDecoration: themeOptions?.typography?.body2.textDecoration ?? 'none'
                },
                overline: {
                    fontSize: themeOptions?.typography?.overline.fontSize ?? '0.75rem',
                    fontFamily: themeOptions?.typography?.overline.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.overline.fontWeight ?? 400,
                    color: themeOptions?.typography?.overline.color,
                    textDecoration: themeOptions?.typography?.overline.textDecoration ?? 'none'
                },
                caption: {
                    fontSize: themeOptions?.typography?.caption.fontSize ?? '0.75rem',
                    fontFamily: themeOptions?.typography?.caption.fontFamily ?? 'Gotham',
                    fontWeight: themeOptions?.typography?.caption.fontWeight ?? 400,
                    color: themeOptions?.typography?.caption.color,
                    textDecoration: themeOptions?.typography?.caption.textDecoration ?? 'none'
                }
            }
        });
    }, [themeObject]);

    useEffect(() => {
        (async () => {
            try {
                const object = await makeRequest();
                dispatch(setTheme(object));
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (themeObject) {
            const toBeLoadedFonts = uniqBy(
                Object.values(themeObject.data.typography ?? {}).filter((item) => {
                    return item.fontFamilyUrl && item.fontFamilyUrl.length > 0;
                }),
                (item) => item.fontFamilyUrl
            );
            for (const font of toBeLoadedFonts) {
                const styleElement = document.head.querySelector(`link[id="${font.fontFamily}-font"]`);
                if (!styleElement) {
                    const link = document.createElement('link');
                    link.id = font.fontFamily + '-font';
                    link.rel = 'stylesheet';
                    link.href = font.fontFamilyUrl!;
                    document.head.appendChild(link);
                }
            }
        }
    }, [themeObject]);

    useEffect(() => {
        // replace all #E6592F with primary color
        for (const stylesheet of document.styleSheets) {
            try {
                // get all css texts
                let css = '';
                for (const rule of stylesheet.cssRules) {
                    css += rule.cssText;
                }
                css = css.replace(/#E6592F/ig, theme.palette.primary.main);
                const node = stylesheet.ownerNode as HTMLStyleElement | null;
                if (node) {
                    node.innerHTML = css;
                }
            } catch (error) {
                if (!(error instanceof DOMException) || !error.name.includes('SecurityError')) {
                    throw error;
                }
            }
        }
    }, [theme]);

    return (
        <BaseThemeProvider theme={theme}>
            <BaseThemeV4Provider theme={themeV4}>
                {props.children}
            </BaseThemeV4Provider>
        </BaseThemeProvider>
    );
}

async function makeRequest(): Promise<ThemeObject | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<{ results: ThemeObject[] }>(
            `${API_HREF}client/${window.id_owner}/theme/`,
            {
                headers,
                params: {
                    is_active: true
                }
            }
        );
        return response.data.results[0] ?? null;
    }

    return null;
}
