import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { isObject } from "lodash";

type Callback = (error: Error) => void

export function useShowError(): Callback {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (error) => {
        console.error(error);

        function isAxiosError(error: Error | AxiosError): error is AxiosError {
            return Boolean((error as AxiosError).response);
        }

        if (isAxiosError(error)) {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 404) {
                    if (isObject(error.response.data)) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            const keyText = t(`shared.${key}`);
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${keyText} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${keyText} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    enqueueSnackbar(t<string>('errors.permissions'), { variant: 'error' });
                } else if (error.response.status === 409) {
                    enqueueSnackbar(t('errors.conflict') + ': ' + error.response.data?.error, { variant: 'error' });
                } else if (error.response.status === 500) {
                    enqueueSnackbar(t<string>('errors.servers'), { variant: 'error' });
                }
            }
            console.log('error.config : ', error.config);
        } else {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
}
