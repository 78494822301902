import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ThemeObject } from "../../MaterialTripList/objects/themeObject";

type State = {
    theme: ThemeObject | null
}

const initialState: State = {
    theme: null
}

const slice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (
            state,
            action: PayloadAction<State['theme']>
        ) => {
            state.theme = action.payload
        },
    }
});

export default slice;

export const {
    setTheme
} = slice.actions;
