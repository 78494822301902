import { cloneDeep } from "lodash";
import CompareFlight from "../Components/FlightMaterial/Functions/CompareFlight";

const quotationCode = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;

const initial_state = {
    show_more_results: null,
    show_stop_over: true,
    stop_over_active: [{
        none: false,
        one: false,
        two: false,
        more: false
    }],
    sort_value: 1,
    show_all_stop_over: true,
    show_all_stop_over_duration: true,
    show_price: true,
    show_price_rate: true,
    public_price: false,
    private_price: false,
    min_price: null,
    max_price: null,
    max_duration: null,
    min_duration: null,
    flight_source_ndc: false,
    flight_source_gds: false,
    filter_max_duration: null,
    filter_min_duration: null,
    slider_max_duration: null,
    slider_min_duration: null,
    filter_min_price: null,
    slider_min_price: null,
    slider_max_price: null,
    filter_max_price: null,
    show_hours: true,
    min_hours: null,
    max_hours: null,
    show_airports: true,
    show_duration_stop_over: true,
    show_max_duration: true,
    show_price_type: true,
    show_checked_luggage: true,
    checked_luggage_included: !(JSON.parse(localStorage.getItem("config")) && JSON.parse(localStorage.getItem("config")).quotation_code === "verdie"),
    checked_luggage: {
        included: quotationCode === 'beachcomber',
        zero: false,
        one: false,
        two: false,
        disabled: true
    },
    show_flight_hours: true,
    show_day_night: true,
    show_class_type: true,
    show_alliances: true,
    show_airlines: true,
    show_cabins: true,
    show_providers: true,
    is_day_flight: false,
    is_night_flight: false,
    show_stop_over_airport: true,
    show_stop_over_duration: true,
    flight_list: [],
    show_all_airports: true,
    show_all_stations: true,
    show_all_stop_over_airport: true,
    airports: null,
    stations: null,
    providers: null,
    show_all_providers: true,
    filter_time: null,
    slider_time: null,
    stopover_airport: null,
    stopover_duration: null,
    synchronized_stopover: false,
    show_all_airlines: true,
    airlines: null,
    show_all_alliances: true,
    show_change_airport: true,
    change_airport: false,
    alliances: null,
    fetch_error: null,
    stop_over: null,
    currency: null,
    currency_symbol: null,
    distance_unit: null,
    selected_flight: null,
    toggle_add_to_cart: false,
    fetch_check_price: null,
    fetch_check_price_error: null,
    price_variation: {
        status: false,
        reload: false,
        group_passenger: null,
        variation: null
    },
    matrix_flight: null,
    matrix_outbound_date: null,
    matrix_return_date: null,
    matrix_length: null,
    matrix_day_selected: null,
    matrix_day_selected2: null,
    fetched_status: null,
    refundable: false,
    not_refundable: false,
    matrix_status: null,
    seat_modal: false,
    seat_info: null,
    error_seat_data: null,
    confirmation_modal: false,
    cancel_state: null,
    summary_seat: false,
    isUpdate: false,
    flight_list_render: [],
    detailed_index: null,
    last_ticketing: null,
    refund_condition: false,
    background_img: "/Img/port-de-cefalu-en-sicile-au-large-de-l-italie.jpg",
    lowest_price: null,
    matrix_data: null,
    date_updated: false,
    option_control: null,
    option_loaded: false,
    seats_loaded: false,
    trace_id: null,
    open_remove_luggage: false,
    providers_count: 0,
    active_page: 1,
    // seats
    selected_seats: [],
    selected_price: 0,
    seats_by_segment: [],
    seat_current_segment: 0
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.flight_search !== undefined) {
                Object.keys(action.payload.flight_search).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.flight_search[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "FLIGHT_SEARCH_RESET": {
            new_state = initial_state;
            return new_state;
        }
        case "FLIGHT_SET_FETCHED_DATA": {
            if (new_state.providers === null) {
                new_state.flight_list = action.payload.flight_list;
                new_state.flight_list_render = action.payload.flight_list;
                // new_state.flight_list_render = getPageResults(action.payload.flight_list, 1);
                new_state.airports = action.payload.airports;
                new_state.airlines = action.payload.airlines;
                new_state.alliances = action.payload.alliances;
                new_state.stop_over = action.payload.stop_over;
                new_state.min_price = action.payload.min_price;
                new_state.max_price = action.payload.max_price;
                new_state.filter_min_price = action.payload.min_price;
                new_state.filter_max_price = action.payload.max_price;
                new_state.slider_min_price = action.payload.min_price;
                new_state.slider_max_price = action.payload.max_price;
                new_state.currency = action.payload.currency;
                new_state.currency_symbol = action.payload.currency_symbol;
                new_state.distance_unit = action.payload.distance_unit;
                new_state.trace_id = action.payload.trace_id;
                new_state.stopover_airport = action.payload.stopover_airport;
                new_state.filter_time = action.payload.filter_time;
                new_state.slider_time = action.payload.filter_time;
                new_state.max_duration = action.payload.max_duration;
                new_state.min_duration = action.payload.min_duration;
                new_state.filter_max_duration = action.payload.max_duration;
                new_state.filter_min_duration = action.payload.min_duration;
                new_state.slider_max_duration = action.payload.max_duration;
                new_state.slider_min_duration = action.payload.min_duration;
                new_state.stations = action.payload.stations;
                new_state.checked_luggage_included = action.payload.luggage;
                new_state.stopover_duration = action.payload.stopover_duration;
                new_state.providers = action.payload.provider;
                new_state.isUpdate = true;
            } else {
                const { flight_list, airports, airlines, alliances, min_price, max_price, stop_over, trace_id, stopover_airport, max_duration, min_duration, stations, provider } = action.payload;
                let new_airlines = state.airlines ? cloneDeep(state.airline) : null;
                let new_airports = Object.assign({}, state.airports);
                let new_alliances = state.alliances ? cloneDeep(state.alliances) : null;
                let new_flight_list = cloneDeep(state.flight_list);
                let new_flight_list_render = cloneDeep(state.flight_list_render);
                let new_max_duration = state.max_duration ? state.max_duration : 0;
                let new_max_price = state.max_price ? state.max_price : 0;
                let new_min_duration = state.min_duration ? state.min_duration : 0;
                let new_min_price = state.min_price ? state.min_price : 0;
                let new_stations = Object.assign({}, state.stations);
                let new_stop_over_airport = state.stopover_airport ? cloneDeep(state.stopover_airport) : null;
                let new_providers = state.providers ? cloneDeep(state.providers) : null;

                if (new_providers) {
                    if (provider && provider.length > 0) {
                        provider.map((provider_obj) => {
                            if (!new_providers.some(item => item.id === provider_obj.id)) {
                                new_providers.push(provider_obj);
                            }
                        });
                    }
                } else {
                    new_providers = provider;
                }
                if (new_airlines) {
                    if (airlines) {
                        airlines.map((airline) => {
                            if (new_airlines) {
                                let found_airline = new_airlines.find(item => item.name === airline.name);
                                if (found_airline === undefined) {
                                    new_airlines.push(airline);
                                } else if (found_airline.price !== undefined && (found_airline.price === 0 || found_airline.price > airline.price)) {
                                    let new_airline_index = new_airlines.findIndex(item => item.name === airline.name);
                                    new_airlines[new_airline_index] = airline;
                                }
                            }
                        });
                    }
                } else {
                    new_airlines = airlines;
                }
                if (new_airports) {
                    if (airports) {
                        Object.keys(airports).forEach(key => {
                            if (new_airports) {
                                if (new_airports[key] === undefined) {
                                    new_airports[key] = airports[key];
                                } else if (new_airports[key].price === 0 || airports[key].price < new_airports[key].price) {
                                    new_airports[key] = airports[key];
                                }
                            }
                        });
                    }
                } else {
                    new_airports = airports;
                }
                if (new_alliances) {
                    if (alliances) {
                        alliances.map((alliance) => {
                            let found_alliance = new_alliances.find(item => item.name === alliance.name);
                            if (new_alliances) {
                                if (found_alliance === undefined) {
                                    new_alliances.push(alliance);
                                }
                            } else if (found_alliance !== undefined && (found_alliance.price === 0 || found_alliance.price > alliance.price)) {
                                let new_alliance_index = new_alliances.findIndex(item => item.name === alliance.name);
                                new_alliances[new_alliance_index] = alliance;
                            }
                        });
                    }
                } else {
                    new_alliances = alliances;
                }
                if (new_flight_list) {
                    if (flight_list) {
                        flight_list.map((flight) => {
                            if (!new_flight_list.some(item => CompareFlight(item, flight))) {
                                new_flight_list.push(flight);
                            }
                        });
                    }
                } else {
                    new_flight_list = flight_list;
                }

                if (new_flight_list_render) {
                    if (flight_list) {
                        flight_list.map((flight) => {
                            let flight_luggage = null;
                            // check that every segment have same number of luggage
                            let nb_luggage_by_segment = [];
                            flight.outbounds.map((segment) => {
                                nb_luggage_by_segment.push(segment.legs[0].baggage_allowance.number_of_pieces);
                            });
                            let all_same_luggage = true;
                            nb_luggage_by_segment.map((segment_nb_luggage) => {
                                if (segment_nb_luggage !== nb_luggage_by_segment[0]) {
                                    all_same_luggage = false;
                                }
                            });
                            if (all_same_luggage) {
                                flight_luggage = nb_luggage_by_segment[0];
                            }

                            if (!new_flight_list_render.some(item => CompareFlight(item, flight))) {
                                new_flight_list_render.push(flight);
                            }
                        });
                    }
                } else {
                    new_flight_list_render = flight_list;
                }

                if (max_duration) {
                    new_max_duration = Math.max(new_max_duration, max_duration);
                } else {
                    new_max_duration = null;
                }
                if (max_price) {
                    new_max_price = Math.max(new_max_price, max_price);
                } else {
                    new_max_price = null;
                }
                if (min_duration) {
                    new_min_duration = Math.min(new_min_duration, min_duration);
                } else {
                    new_min_duration = null;
                }
                if (min_price) {
                    new_min_price = Math.min(new_min_price, min_price);
                } else {
                    new_min_price = null;
                }
                if (new_stations) {
                    if (stations) {
                        Object.keys(stations).forEach(key => {
                            if (new_stations) {
                                if (new_stations[key] === undefined) {
                                    new_stations[key] = stations[key];
                                } else if (new_stations[key].price === 0 || stations[key].price < new_stations[key].price) {
                                    new_stations[key] = stations[key];
                                }
                            }
                        });
                    }
                } else {
                    new_stations = stations;
                }
                if (new_stop_over_airport) {
                    if (stopover_airport) {
                        stopover_airport.map((stop_over_outbound, outbound_index) => {
                            Object.keys(stop_over_outbound).forEach(key => {
                                if (new_stop_over_airport) {
                                    if (new_stop_over_airport[outbound_index] !== undefined) {
                                        if (new_stop_over_airport[outbound_index][key] === undefined) {
                                            new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                        } else if (new_stop_over_airport[outbound_index][key].price !== undefined && stop_over_outbound.price !== undefined && new_stop_over_airport[outbound_index][key].price < stop_over_outbound[key].price) {
                                            new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                        }
                                    }
                                }
                            });
                        });
                    }
                } else {
                    new_stop_over_airport = stopover_airport;
                }
                if (new_airlines) {
                    new_state.airlines = new_airlines.sort((a, b) => {
                        return (a.price - b.price);
                    });
                }

                new_state.airports = new_airports;
                new_state.alliances = new_alliances;
                new_state.providers = new_providers;
                new_state.filter_max_duration = new_max_duration;
                if (new_max_price) {
                    new_state.filter_max_price = Math.ceil(new_max_price);
                    new_state.slider_max_price = Math.ceil(new_max_price);
                }
                new_state.filter_min_duration = new_min_duration;
                if (new_min_price) {
                    new_state.filter_min_price = Math.floor(new_min_price);
                    new_state.slider_min_price = Math.floor(new_min_price);
                }

                // reaffect flight id as index
                new_flight_list.map((flight, flight_index) => {
                    new_flight_list[flight_index].id = flight.id;
                });
                new_flight_list_render.map((flight, flight_index) => {
                    new_flight_list_render[flight_index].id = flight.id;
                });
                new_flight_list = new_flight_list.sort((a, b) => {
                    return parseFloat(a.total_price) - parseFloat(b.total_price);
                });
                new_state.flight_list = new_flight_list;
                new_state.flight_list_render = new_flight_list;
                // new_state.flight_list_render = getPageResults(new_flight_list, 1);
                new_state.max_duration = new_max_duration;
                new_state.max_price = new_max_price;
                new_state.min_duration = new_min_duration;
                new_state.min_price = new_min_price;
                new_state.slider_max_duration = new_max_duration;
                new_state.slider_min_duration = new_min_duration;
                new_state.stations = new_stations;
                new_state.stop_over = stop_over;
                new_state.stopover_airport = new_stop_over_airport;
                new_state.trace_id = trace_id;
                new_state.isUpdate = true;
            }
            return new_state;
        }
        case "FLIGHT_SET_MANUAL_INFO": {
            new_state.flight_list_render = action.payload.flight_list;
            new_state.airlines = action.payload.airlines;
            new_state.airports = action.payload.airports;
            new_state.stations = action.payload.stations;
            new_state.trace_id = action.payload.trace_id;
            new_state.last_ticketing = action.payload.last_ticketing;
            new_state.selected_flight = 0;
            new_state.option_loaded = true;
            return new_state;
        }
        case "FLIGHT_SET_FETCHED_OPTIONS": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options = [].concat([], action.payload.options);
                            copy_outbound.empty_option = action.payload.is_empty;
                            copy_outbound.more_option = action.payload.more_option;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_GET_OPTIONS": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.options = action.payload.options;

                    // update segment key & fare basis
                    action.payload.options.segments.map((segment, segment_index) => {
                        if (segment_index < copy_flight.outbounds.length) {
                            let copy_outbound = Object.assign({}, flight.outbounds[segment_index]);
                            segment.map((leg, leg_index) => {
                                if (leg_index < copy_outbound.legs.length) {
                                    let copy_leg = Object.assign({}, copy_outbound.legs[leg_index]);
                                    copy_leg.fare_basis = leg.fare_basis;
                                    if (leg.key_segment !== undefined) {
                                        // copy_leg.segment_key = leg.key_segment;
                                        copy_leg.key_segment = leg.key_segment;
                                    } else if (leg.key_segments !== undefined) {
                                        // copy_leg.segment_key = leg.key_segments;
                                        copy_leg.key_segments = leg.key_segments;
                                    }
                                    copy_outbound.legs[leg_index] = copy_leg;
                                }
                            });
                            copy_flight.outbounds[segment_index] = copy_outbound;
                        }
                    });
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            new_state.get_options = true;
            return new_state;
        }
        case "FLIGHT_SET_OPTIONS_ERROR": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.error_options = action.payload.error;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            new_state.detailed_index = action.payload.flight_index;
            return new_state;
        }
        case "FLIGHT_SET_SELECTED_OPTION" : {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        let copy_outbound = Object.assign({}, outbound);
                        copy_outbound.selected_option = action.payload.selected_option;
                        copy_outbound.key_pricing = action.payload.key_pricing;
                        outbound = copy_outbound;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_EMPTY_OPTIONS": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.empty_option = true;
                            copy_outbound.more_option = action.payload.more_option;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_FETCH_ERROR_OPTIONS": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options_error = action.payload.fetch_error;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_RESET_FETCH_ERROR_OPTIONS": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.options_error = null;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_STATE": {
            let show_more_results = new_state.show_more_results;
            new_state = initial_state;
            new_state.show_more_results = show_more_results;
            return new_state;
        }
        case "FLIGHT_SET_FETCH_ERROR": {
            new_state.fetch_error = action.payload.fetch_error;
            return new_state;
        }
        case "FLIGHT_RESET_FETCH_ERROR" : {
            new_state.fetch_error = null;
            return new_state;
        }
        case "FLIGHT_CLEAR_FILTER": {
            new_state.show_all_airlines = true;
            let airlines = cloneDeep(new_state.airlines);
            let alliances = cloneDeep(new_state.alliances);
            let providers = new_state.providers ? cloneDeep(new_state.providers) : null;
            let airports = Object.assign({}, new_state.airports);
            let stations = Object.assign({}, new_state.stations);
            let stopover_airport = cloneDeep(new_state.stopover_airport);
            let stop_over_active = cloneDeep(new_state.stop_over_active);
            airlines.forEach(key => {
                key.displayed = false;
            });
            new_state.show_all_alliances = true;
            alliances.forEach(key => {
                key.displayed = false;
            });
            new_state.show_all_airports = true;
            Object.keys(airports).forEach(key => {
                airports[key].displayed = false;
            });
            new_state.show_all_stations = true;
            Object.keys(stations).forEach(key => {
                stations[key].displayed = false;
            });
            new_state.show_all_stop_over_airport = true;
            stopover_airport.map((stopover) => {
                let copy_stopover = Object.assign({}, stopover);
                Object.keys(copy_stopover).forEach(key => {
                    copy_stopover[key].displayed = false;
                });
            });
            new_state.show_all_providers = true;
            if (providers) {
                providers.forEach(key => {
                    key.displayed = false;
                });
            }
            stop_over_active.map((outbound_stop_over) => {
                Object.keys(outbound_stop_over).map((key) => {
                    if (outbound_stop_over[key] === true) {
                        outbound_stop_over[key] = false;
                    }
                });
            });
            new_state.stop_over_active = stop_over_active;
            new_state.filter_time = action.payload.filter_time;
            new_state.slider_time = action.payload.filter_time;
            new_state.airlines = airlines;
            new_state.alliances = alliances;
            new_state.stopover_airport = stopover_airport;
            new_state.airports = airports;
            new_state.stations = stations;
            new_state.filter_min_price = new_state.min_price;
            new_state.filter_max_price = new_state.max_price;
            new_state.slider_min_price = new_state.min_price;
            new_state.slider_max_price = new_state.max_price;
            new_state.filter_min_duration = new_state.min_duration;
            new_state.filter_max_duration = new_state.max_duration;
            new_state.slider_min_duration = new_state.min_duration;
            new_state.slider_max_duration = new_state.max_duration;
            new_state.is_day_flight = false;
            new_state.is_night_flight = false;
            new_state.refundable = false;
            new_state.not_refundable = false;
            new_state.isUpdate = true;
            new_state.checked_luggage_included = !(JSON.parse(localStorage.getItem("config")) && JSON.parse(localStorage.getItem("config")).quotation_code === "verdie");
            new_state.checked_luggage = {
                included: quotationCode === 'beachcomber',
                zero: false,
                one: false,
                two: false,
                disabled: true
            };
            new_state.change_airport = false;
            new_state.show_all_stop_over = true;
            new_state.show_all_stop_over_duration = true;
            new_state.public_price = false;
            new_state.private_price = false;
            new_state.flight_source_gds = false;
            new_state.flight_source_ndc = false;
            new_state.providers = providers;

            let new_stopover_duration = [];
            new_state.stopover_duration.map((outbound) => {
                let copy_outbound = Object.assign({}, outbound);
                Object.keys(outbound).map((key) => {
                    copy_outbound[key] = false;
                });
                new_stopover_duration.push(copy_outbound);
            });
            new_state.stopover_duration = new_stopover_duration;
            return new_state;
        }
        case "FLIGHT_CLEAR_FLIGHT_LIST": {
            new_state.flight_list_render = [];
            return new_state;
        }
        case "FLIGHT_TOGGLE_STOP_OVER_FILTER": {
            new_state.show_stop_over = !new_state.show_stop_over;
            return new_state;
        }
        case "FLIGHT_INIT_STOP_OVER_ACTIVE": {
            let temp_stop_over_active = [];
            let nb_journey = action.payload.nb_journey;
            for (let i = 0; i < nb_journey; i++) {
                temp_stop_over_active.push({
                    none: false,
                    one: false,
                    two: false,
                    more: false
                });
            }
            new_state.stop_over_active = temp_stop_over_active;
            return new_state;
        }
        case "FLIGHT_TOGGLE_SHOW_ALL_STOP_OVER":{
            new_state.show_all_stop_over = true;
            new_state.isUpdate = true;
            let new_stop_over_active = [];
            new_state.stop_over_active.map((segment_stop_over) => {
                let copy_stop_over_active = Object.assign({}, segment_stop_over);
                copy_stop_over_active.none = false;
                copy_stop_over_active.one = false;
                copy_stop_over_active.two = false;
                copy_stop_over_active.more = false;
                new_stop_over_active.push(copy_stop_over_active);
            });
            new_state.stop_over_active = new_stop_over_active;
            return new_state;
        }
        case "FLIGHT_TOGGLE_ACTIVE_STOP_OVER_FILTER": {
            let stop_over_active = cloneDeep(new_state.stop_over_active);
            let segment_stop_over = Object.assign({}, stop_over_active[action.payload.segment_index]);
            segment_stop_over[action.payload.key] = !segment_stop_over[action.payload.key];
            stop_over_active[action.payload.segment_index] = segment_stop_over;
            new_state.stop_over_active = stop_over_active;

            let all_true = true;
            let all_false = true;
            stop_over_active.map((parse_stop_over) => {
                Object.keys(parse_stop_over).map((key) => {
                    if (parse_stop_over[key]) {
                        all_false = false;
                    } else {
                        all_true = false;
                    }
                });
            });
            new_state.show_all_stop_over = (all_true || all_false);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SET_PRICE_FILTER": {
            new_state.filter_min_price = action.payload.filter_min_price;
            new_state.filter_max_price = action.payload.filter_max_price;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SET_PRICE_SLIDER": {
            new_state.slider_min_price = action.payload.slider_min_price;
            new_state.slider_max_price = action.payload.slider_max_price;
            return new_state;
        }
        case "FLIGHT_SET_MAX_DURATION_FILTER": {
            new_state.filter_min_duration = action.payload.filter_min_duration;
            new_state.filter_max_duration = action.payload.filter_max_duration;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SET_MAX_DURATION_SLIDER": {
            new_state.slider_min_duration = action.payload.slider_min_duration;
            new_state.slider_max_duration = action.payload.slider_max_duration;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PRICE_FILTER": {
            new_state.show_price = !new_state.show_price;
            return new_state;
        }
        case "FLIGHT_TOGGLE_ALLIANCE_FILTER": {
            new_state.show_alliances = !new_state.show_alliances;
            return new_state;
        }
        case "FLIGHT_TOGGLE_ALLIANCE": {
            let alliances = cloneDeep(new_state.alliances);
            let alliance = Object.assign({}, alliances[action.payload.index]);
            alliance.displayed = !alliance.displayed;
            alliances[action.payload.index] = alliance;
            new_state.alliances = alliances;
            let all_true = true;
            let all_false = true;
            new_state.alliances.map((alliance) => {
                if (alliance.displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            new_state.show_all_alliances = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_FLIGHT_HOURS_FILTER": {
            new_state.show_flight_hours = !new_state.show_flight_hours;
            return new_state;
        }
        case "FLIGHT_TOGGLE_AIRPORT_FILTER": {
            new_state.show_airports = !new_state.show_airports;
            return new_state;
        }
        case "FLIGHT_TOGGLE_STOPOVER_AIRPORT_FILTER": {
            new_state.show_stop_over_airport = !new_state.show_stop_over_airport;
            return new_state;
        }
        case "FLIGHT_TOGGLE_STOPOVER_DURATION_FILTER": {
            new_state.show_stop_over_duration = !new_state.show_stop_over_duration;
            return new_state;
        }
        case "FLIGHT_SHOW_ALL_AIRPORTS": {
            Object.keys(new_state.airports).forEach(key => {
                let copy_airport = Object.assign({}, new_state.airports[key]);
                copy_airport.displayed = false;
                new_state.airports[key] = copy_airport;
            });
            new_state.show_all_airports = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_AIRPORT": {
            let airports = Object.assign({}, new_state.airports);
            let airport = Object.assign({}, airports[action.payload.index]);
            airport.displayed = !airport.displayed;
            airports[action.payload.index] = airport;
            new_state.airports = airports;
            let all_true = true;
            let all_false = true;
            Object.keys(new_state.airports).forEach(key => {
                if (new_state.airports[key].displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            new_state.show_all_airports = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SHOW_ALL_STOPOVER_AIRPORT": {
            let new_airports = [];
            new_state.stopover_airport.map((journey_airports) => {
                Object.keys(journey_airports).forEach(key => {
                    let copy_airport = Object.assign({}, journey_airports[key]);
                    copy_airport.displayed = false;
                    journey_airports[key] = copy_airport;
                });
                new_airports.push(journey_airports);
            });

            new_state.stopover_airport = new_airports;
            new_state.show_all_stop_over_airport = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_STOPOVER_AIRPORT": {
            let airports = cloneDeep(new_state.stopover_airport);
            let copy_airport = [];
            airports.map((outbound, outbound_index) => {
                if (action.payload.index_journey === outbound_index) {
                    let airport = Object.assign({}, outbound);
                    Object.keys(airport).map((key) => {
                        if (key === action.payload.airport) {
                            let stop = Object.assign({}, airport[key]);
                            stop.displayed = !stop.displayed;
                            airport[key] = stop;
                        } else {
                            let stop = Object.assign({}, airport[key]);
                            stop.displayed = false;
                            airport[key] = stop;
                        }
                    });
                    outbound = airport;
                }
                copy_airport.push(outbound);
            });
            new_state.stopover_airport = copy_airport;
            let all_true = true;
            let all_false = true;
            new_state.stopover_airport.map((outbound) => {
                Object.keys(outbound).map((airport) => {
                    if (outbound[airport].displayed) {
                        all_false = false;
                    } else {
                        all_true = false;
                    }
                });
            });
            new_state.show_all_stop_over_airport = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SHOW_ALL_STATIONS": {
            Object.keys(new_state.stations).forEach(key => {
                let copy_station = Object.assign({}, new_state.stations[key]);
                copy_station.displayed = false;
                new_state.stations[key] = copy_station;
            });
            new_state.show_all_stations = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_STATION": {
            let stations = Object.assign({}, new_state.stations);
            let station = Object.assign({}, stations[action.payload.index]);
            station.displayed = !station.displayed;
            stations[action.payload.index] = station;
            new_state.stations = stations;
            let all_true = true;
            let all_false = true;
            Object.keys(new_state.stations).forEach(key => {
                if (new_state.stations[key].displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            new_state.show_all_stations = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_ALL_STOPOVER_DURATION": {
            let new_stopover_duration = [];

            new_state.stopover_duration.map((outbound) => {
                let copy_outbound = Object.assign({}, outbound);
                Object.keys(outbound).map((key) => {
                    copy_outbound[key] = false;
                });
                new_stopover_duration.push(copy_outbound);
            });

            new_state.stopover_duration = new_stopover_duration;
            new_state.show_all_stop_over_duration = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_CREATE_STOPOVER_DURATION": {
            let new_stopover_duration = [];
            for (let i=0 ; i<action.payload.nb_journeys ; i++) {
                new_stopover_duration.push({
                    two_hours: false,
                    two_ten_hours: false,
                    ten_hours: false,
                    displayed: true
                });
            }
            new_state.stopover_duration = new_stopover_duration;
            new_state.show_all_stop_over_duration = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_STOPOVER_DURATION": {
            let stopover_duration = cloneDeep(new_state.stopover_duration);
            let synchronized_stopover = new_state.synchronized_stopover;
            let new_stopover_duration = [];
            let temp_show_all_stop_over_duration = true;

            stopover_duration.map((outbound, outbound_index) => {
                if (synchronized_stopover) {
                    let copy_outbound = Object.assign({}, outbound);
                    Object.keys(copy_outbound).map((key) => {
                        if (key === action.payload.key) {
                            copy_outbound[key] = !stopover_duration[action.payload.index_journey][key];
                        }
                        if (copy_outbound[key]) {
                            temp_show_all_stop_over_duration = false;
                        }
                    });
                    outbound = copy_outbound;
                } else if (action.payload.index_journey === outbound_index) {
                    let copy_outbound = Object.assign({}, outbound);
                    Object.keys(copy_outbound).map((key) => {
                        if (key === action.payload.key) {
                            copy_outbound[key] = !copy_outbound[key];
                        }
                        if (copy_outbound[key]) {
                            temp_show_all_stop_over_duration = false;
                        }
                    });
                    outbound = copy_outbound;
                }
                new_stopover_duration.push(outbound);
            });
            new_state.stopover_duration = new_stopover_duration;
            new_state.isUpdate = true;
            new_state.show_all_stop_over_duration = temp_show_all_stop_over_duration;
            return new_state;
        }
        case "FLIGHT_RESET_FILTER_UPDATE": {
            new_state.isUpdate = false;
            return new_state;
        }
        case "FLIGHT_TOGGLE_AIRLINE_FILTER": {
            new_state.show_airlines = !new_state.show_airlines;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PROVIDERS_FILTER": {
            new_state.show_providers = !new_state.show_providers;
            return new_state;
        }
        case "FLIGHT_CREATE_FLIGHT_HOURS":{
            let new_time = [];
            for (let i=0 ; i<action.payload.nb_journeys ; i++) {
                new_time.push({
                    arrival: [0, 24],
                    departure: [0, 24]
                });
            }
            new_state.filter_time = new_time;
            new_state.slider_time = new_time;

            new_state.show_all_stop_over_duration = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SET_FLIGHT_HOURS": {
            let filter_time = cloneDeep(new_state.filter_time);
            let copy_filter_time = [];
            filter_time.map((outbound, index) => {
                if (index === action.payload.index) {
                    let copy_outbound = Object.assign({}, outbound);
                    let copy_type = copy_outbound[action.payload.type].slice();
                    copy_type[0] = action.payload.start;
                    copy_type[1] = action.payload.end;
                    copy_outbound[action.payload.type] = copy_type;
                    outbound = copy_outbound;
                }
                copy_filter_time.push(outbound);
            });
            new_state.filter_time = copy_filter_time;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SET_FLIGHT_HOURS_SLIDE": {
            let slider_time = cloneDeep(new_state.slider_time);
            let copy_slider_time = [];
            slider_time.map((outbound, index) => {
                if (index === action.payload.index) {
                    let copy_outbound = Object.assign({}, outbound);
                    let copy_type = copy_outbound[action.payload.type].slice();
                    copy_type[0] = action.payload.start;
                    copy_type[1] = action.payload.end;
                    copy_outbound[action.payload.type] = copy_type;
                    outbound = copy_outbound;
                }
                copy_slider_time.push(outbound);
            });
            new_state.slider_time = copy_slider_time;
            return new_state;
        }
        case "FLIGHT_SET_SLIDER_TIME": {
            return { ...new_state, slider_time: action.payload, isUpdate: true };
        }
        case "FLIGHT_SHOW_ALL_AIRLINES": {
            let new_airlines = [];
            new_state.airlines.map((airline) => {
                let copy_airline = Object.assign({}, airline);
                copy_airline.displayed = false;
                new_airlines.push(copy_airline);
            });

            new_state.airlines = new_airlines;
            new_state.show_all_airlines = true;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_AIRLINE": {
            let airlines = cloneDeep(new_state.airlines);
            let copy_airlines = [];
            airlines.map((airline) => {
                if (airline.id === action.payload.index) {
                    let copy_airline = Object.assign({}, airline);
                    copy_airline.displayed = !copy_airline.displayed;
                    airline = copy_airline;
                }
                copy_airlines.push(airline);
            });
            new_state.airlines = copy_airlines;
            let all_true = true;
            let all_false = true;
            new_state.airlines.map((airline) => {
                if (airline.displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            new_state.show_all_airlines = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PROVIDER": {
            let providers = cloneDeep(new_state.providers);
            let copy_providers = [];
            providers.map((provider) => {
                if (provider.id === action.payload.index) {
                    let copy_provider = Object.assign({}, provider);
                    copy_provider.displayed = !copy_provider.displayed;
                    provider = copy_provider;
                }
                copy_providers.push(provider);
            });
            new_state.providers = copy_providers;
            let all_true = true;
            let all_false = true;
            new_state.providers.map((provider) => {
                if (provider.displayed) {
                    all_false = false;
                } else {
                    all_true = false;
                }
            });
            new_state.show_all_providers = (all_false || all_true);
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_DETAIL": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            let detail_open = null;
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    detail_open = action.payload.flight_index;
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.outbound_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.detail = action.payload.detail;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.detailed_index = detail_open;
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_TOGGLE_DETAIL_PRICE": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.detail_price = action.payload.detail;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SET_TAB": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];

            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.segment_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.tab = action.payload.tab;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;

            return new_state;
        }
        case "FLIGHT_TOGGLE_CABIN_FILTER": {
            new_state.show_cabins = !new_state.show_cabins;
            return new_state;
        }
        case "FLIGHT_TOGGLE_DAY_NIGHT_FILTER": {
            new_state.show_day_night = !new_state.show_day_night;
            return new_state;
        }
        case "FLIGHT_TOGGLE_DAY_FLIGHT": {
            new_state.is_day_flight = !new_state.is_day_flight;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_NIGHT_FLIGHT": {
            new_state.is_night_flight = !new_state.is_night_flight;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_CHECKED_LUGGAGE_FILTER": {
            new_state.show_checked_luggage = !new_state.show_checked_luggage;
            return new_state;
        }
        case "FLIGHT_SET_CHECKED_LUGGAGE": {
            let temp_checked_luggage = Object.assign({}, new_state.checked_luggage);
            let temp_disabled = true;
            Object.keys(temp_checked_luggage).map((key) => {
                if (action.payload.key !== "disabled") {
                    if (action.payload.key === "zero") {
                        if (key === action.payload.key) {
                            temp_checked_luggage[key] = !temp_checked_luggage[key];
                        } else if (temp_checked_luggage[key]) {
                            temp_checked_luggage[key] = false;
                        }
                    } else if (key === action.payload.key) {
                        temp_checked_luggage[key] = !temp_checked_luggage[key];
                    } else if (key === 'zero' && temp_checked_luggage[key]) {
                        temp_checked_luggage[key] = false;
                    }
                    if (temp_checked_luggage[key]) {
                        temp_disabled = false;
                    }
                } else if (key === action.payload.key) {
                    temp_checked_luggage[key] = true;
                } else if (temp_checked_luggage[key]) {
                    temp_checked_luggage[key] = false;
                }
            });
            temp_checked_luggage.disabled = temp_disabled;
            new_state.checked_luggage = temp_checked_luggage;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_CHECKED_LUGGAGE_INCLUDED": {
            new_state.checked_luggage_included = !new_state.checked_luggage_included;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PRICE_TYPE_FILTER": {
            new_state.show_price_type = !new_state.show_price_type;
            return new_state;
        }
        case "FLIGHT_TOGGLE_REFUNDABLE": {
            new_state.refundable = !new_state.refundable;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_NOT_REFUNDABLE": {
            new_state.not_refundable = !new_state.not_refundable;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_SELECTED_FLIGHT": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flight_list = [];
            let copy_outbounds = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_id) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        outbound.detail = false;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flight_list.push(flight);
            });
            new_state.flight_list_render = copy_flight_list;
            new_state.selected_flight = action.payload.flight_id;
            return new_state;
        }
        case "FLIGHT_RESET_SELECTED_FLIGHT": {
            new_state.selected_flight = null;
            return new_state;
        }
        case "FLIGHT_TOGGLE_ADD_TO_CART": {
            new_state.toggle_add_to_cart = action.payload.add_to_cart;
            return new_state;
        }
        case "FLIGHT_SET_PRICE_VARIATION": {
            new_state.price_variation = action.payload.price_variation;
            return new_state;
        }
        case "FLIGHT_SET_CART_ERROR": {
            new_state.fetch_check_price_error = action.payload.error;
            return new_state;
        }
        case "FLIGHT_SET_MATRIX_DATA": {
            new_state.matrix_flight = action.payload.matrix_flight;
            new_state.matrix_outbound_date = action.payload.matrix_outbound_date;
            new_state.matrix_return_date = action.payload.matrix_return_date;
            new_state.matrix_length = action.payload.matrix_length;
            new_state.lowest_price = action.payload.lowest_price;
            new_state.matrix_data = action.payload.matrix_data;
            return new_state;
        }
        case "FLIGHT_MATRIX_SELECTED_DAY": {
            let matrix_flights = cloneDeep(new_state.matrix_flight);
            let copy_matrix_flight = [];
            matrix_flights.map((matrix_flight) => {
                let copy_flight = Object.assign({}, matrix_flight);
                copy_flight.selected = false;
                copy_flight.highlight = false;
                if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                    copy_flight.selected = true;
                }
                matrix_flight = copy_flight;
                copy_matrix_flight.push(matrix_flight);
            });
            new_state.matrix_flight = copy_matrix_flight;
            return new_state;
        }
        case "FLIGHT_ADD_MATRIX_HOVER": {
            let matrix_flights = cloneDeep(new_state.matrix_flight);
            let copy_matrix_flight = [];
            matrix_flights.map((matrix_flight, index) => {
                let copy_flight = Object.assign({}, matrix_flight);
                if (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) {
                    copy_flight.hover_selected = true;
                }
                if ((copy_flight.index_start_date <= action.payload.index_start && copy_flight.index_return_date === action.payload.index_return) || (copy_flight.index_start_date === action.payload.index_start && copy_flight.index_return_date <= action.payload.index_return)) {
                    copy_flight.hover = true;
                }
                matrix_flight = copy_flight;
                copy_matrix_flight.push(matrix_flight);
            });
            new_state.matrix_flight = copy_matrix_flight;
            return new_state;
        }
        case "FLIGHT_RESET_MATRIX_HOVER": {
            let matrix_flights = cloneDeep(new_state.matrix_flight);
            let copy_matrix_flight = [];
            matrix_flights.map((matrix_flight) => {
                let copy_flight = Object.assign({}, matrix_flight);
                copy_flight.hover = false;
                copy_flight.hover_selected = false;
                matrix_flight = copy_flight;
                copy_matrix_flight.push(matrix_flight);
            });
            new_state.matrix_flight = copy_matrix_flight;
            return new_state;
        }
        case "FLIGHT_FETCHED_STATUS": {
            new_state.fetched_status = action.payload.status;
            return new_state;
        }
        case "FLIGHT_MATRIX_STATUS": {
            new_state.matrix_status = action.payload.status;
            if (new_state.matrix_status === 'Waiting') {
                new_state.fetched_status = null;
            }
            return new_state;
        }
        case "FLIGHT_SET_SELECTED_DAY": {
            new_state.matrix_day_selected = action.payload.index_start_date;
            new_state.matrix_day_selected2 = action.payload.index_return_date;
            return new_state;
        }
        case "FLIGHT_TOGGLE_SEAT_MODAL": {
            new_state.seat_modal = action.payload.is_open;
            new_state.seat_info = action.payload.modal_info;
            new_state.error_seat_data = action.payload.error;
            return new_state;
        }
        case "FLIGHT_SET_SEAT_DATA": {
            new_state.seat_info = action.payload.modal_info;
            return new_state;
        }
        case "FLIGHT_SET_ERROR_SEAT_DATA": {
            new_state.error_seat_data = action.payload.error;
            return new_state;
        }
        case "FLIGHT_SET_SEAT_TAB": {
            let seat_info = Object.assign({}, new_state.seat_info);
            seat_info.tab = action.payload.tab;
            new_state.seat_info = seat_info;
            new_state.tmp_seat = {};
            return new_state;
        }
        case "FLIGHT_SELECTED_SEAT": {
            new_state.seat_selected = {
                seat: action.payload.seat,
                price: action.payload.price
            };
            return new_state;
        }
        case "FLIGHT_SET_UPPER_CABIN": {
            let seat_info = Object.assign({}, new_state.seat_info);
            let flights = cloneDeep(seat_info.flight);
            let copy_flights = [];
            flights.map((flight) => {
                if (flight.id === action.payload.tab) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.upper = action.payload.flag;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            seat_info.flight = copy_flights;
            new_state.seat_info = seat_info;
            return new_state;
        }
        case "FLIGHT_ADD_PASSENGER_SEAT": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let tmp_seat = Object.assign({}, new_state.tmp_seat);
            let copy_outbounds = [];
            let copy_flights = [];
            let copy_legs = [];

            tmp_seat[action.payload.traveler] = action.payload.seat;

            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        if (outbound_index === action.payload.outbound_index) {
                            let copy_outbound = Object.assign({}, outbound);
                            copy_outbound.legs.map((leg, leg_index) => {
                                if (leg_index === action.payload.leg_index) {
                                    let copy_leg = Object.assign({}, leg);
                                    let copy_seats = Object.assign({}, copy_leg.seats);
                                    copy_seats[action.payload.traveler] = {
                                        seat: action.payload.seat,
                                        price: action.payload.price
                                    };
                                    copy_leg.seats = copy_seats;
                                    leg = copy_leg;
                                }
                                copy_legs.push(leg);
                            });
                            copy_outbound.legs = copy_legs;
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            new_state.tmp_seat = tmp_seat;
            return new_state;
        }
        case "FLIGHT_RESET_SEAT": {
            new_state.tmp_seat = {};
            return new_state;
        }
        case "FLIGHT_SHOW_POPOVER": {
            let seat_info = Object.assign({}, new_state.seat_info);
            let copy_tabs = [];
            let seat_rows = [];
            let copy_seats = [];

            seat_info.flight.map((tab, tab_index) => {
                if (tab_index === action.payload.tab) {
                    let copy_tab = Object.assign({}, tab);
                    copy_tab.seat_rows.map((seat_row) => {
                        if (seat_row.number === action.payload.row_index) {
                            let copy_seat_row = Object.assign({}, seat_row);
                            copy_seat_row.seats.map((seat, seat_index) => {
                                if (seat_index === action.payload.seat_index) {
                                    let copy_seat = Object.assign({}, seat);
                                    copy_seat.show = action.payload.show;
                                    seat = copy_seat;
                                }
                                copy_seats.push(seat);
                            });
                            copy_seat_row.seats = copy_seats;
                            seat_row = copy_seat_row;
                        }
                        seat_rows.push(seat_row);
                    });
                    copy_tab.seat_rows = seat_rows;
                    tab = copy_tab;
                }
                copy_tabs.push(tab);
            });
            seat_info.flight = copy_tabs;
            new_state.seat_info = seat_info;
            return new_state;
        }
        case "FLIGHT_SET_CONFIRMATION": {
            new_state.confirmation_modal = action.payload.flag;
            new_state.cancel_state = action.payload.cancel;
            return new_state;
        }
        case "FLIGHT_SET_SEAT_SUMMARY": {
            new_state.summary_seat = action.payload.flag;
            new_state.cancel_state = action.payload.cancel;
            return new_state;
        }
        case "FLIGHT_TOGGLE_CHANGE_AIRPORT_FILTER": {
            new_state.show_change_airport = !new_state.show_change_airport;
            return new_state;
        }
        case "FLIGHT_TOGGLE_CHANGE_AIRPORT": {
            new_state.change_airport = !new_state.change_airport;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_ADD_BAGGAGE_OPTION": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            let segment_key = action.payload.segment_key;
            let traveler_id = action.payload.traveler;
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound, outbound_index) => {
                        let copy_outbound = Object.assign({}, outbound);
                        if (outbound_index === action.payload.outbound_index) {
                            copy_outbound.legs.map((leg, leg_index) => {
                                if (segment_key === leg.segment_key || segment_key === leg.key) {
                                    let copy_leg = Object.assign({}, leg);
                                    let paid_options = Object.assign({}, copy_leg.paid_options);
                                    paid_options[traveler_id] = paid_options[traveler_id] || [];
                                    if (action.payload.baggage) {
                                        if (paid_options[traveler_id][action.payload.type] !== undefined) {
                                            paid_options[traveler_id][action.payload.type] = action.payload.baggage;
                                        } else {
                                            paid_options[traveler_id].push(action.payload.baggage);
                                        }
                                    } else if (action.payload.type !== 0) {
                                        paid_options[traveler_id] = paid_options[traveler_id].filter((el, el_index) => {
                                            return el_index !== action.payload.type;
                                        });
                                    } else {
                                        delete paid_options[traveler_id];
                                    }
                                    copy_leg.paid_options = paid_options;
                                    copy_outbound.legs[leg_index] = copy_leg;
                                }
                            });
                            outbound = copy_outbound;
                        }
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_SELECT_QUANTITY": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_outbounds = [];
            let copy_flights = [];
            let copy_more_options = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    copy_flight.outbounds.map((outbound) => {
                        let copy_outbound = Object.assign({}, outbound);
                        let paid_options = Object.assign({}, copy_outbound.paid_options);
                        let copy_options = paid_options[action.payload.traveler].slice();
                        copy_options.map((option) => {
                            if (option.key_optional_service === action.payload.key_optional_service) {
                                let copy_option = Object.assign({}, option);
                                copy_option.quantity = action.payload.quantity;
                                option = copy_option;
                            }
                            copy_more_options.push(option);
                        });
                        copy_options = copy_more_options;
                        paid_options[action.payload.traveler] = copy_options;
                        copy_outbound.paid_options = paid_options;
                        outbound = copy_outbound;
                        copy_outbounds.push(outbound);
                    });
                    copy_flight.outbounds = copy_outbounds;
                    flight = copy_flight;
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_REMOVE_BAGGAGE_OPTION": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flights = [];
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    let copy_outbound = Object.assign({}, copy_flight.outbounds[action.payload.outbound_index]);
                    let copy_leg = Object.assign({}, copy_outbound.legs.find(item => (item.segment_key === action.payload.segment_key|| item.key === action.payload.segment_key)));
                    let leg_index = copy_outbound.legs.findIndex(item => (item.segment_key === action.payload.segment_key || item.key === action.payload.segment_key));
                    if (copy_leg !== undefined && leg_index !== -1) {
                        let paid_options = Object.assign({}, copy_leg.paid_options);
                        let new_options_travelers = [];
                        paid_options[action.payload.traveler] !== undefined && paid_options[action.payload.traveler].map((option, option_index) => {
                            if (option_index !== action.payload.baggage_index) {
                                new_options_travelers.push(option);
                            }
                        });
                        if (new_options_travelers.length > 0) {
                            paid_options[action.payload.traveler] = new_options_travelers;
                        } else {
                            delete paid_options[action.payload.traveler];
                        }
                        copy_leg.paid_options = paid_options;
                        copy_outbound.legs[leg_index] = copy_leg;
                        copy_flight.outbounds[action.payload.outbound_index] = copy_outbound;
                        flight = copy_flight;
                    }
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_REMOVE_PASSENGER_BAGGAGE": {
            let flight_list = cloneDeep(new_state.flight_list_render);
            let copy_flights = [];
            let traveler_id = action.payload.traveler;
            flight_list.map((flight) => {
                if (flight.id === action.payload.flight_index) {
                    let copy_flight = Object.assign({}, flight);
                    let copy_outbound = Object.assign({}, copy_flight.outbounds[action.payload.outbound_index]);
                    let copy_leg = Object.assign({}, copy_outbound.legs.find(item => (item.segment_key === action.payload.segment_key || item.key === action.payload.segment_key)));
                    let leg_index = copy_outbound.legs.findIndex(item => (item.segment_key === action.payload.segment_key || item.key === action.payload.segment_key));
                    if (copy_leg !== undefined && leg_index !== -1) {
                        let paid_options = Object.assign({}, copy_leg.paid_options);
                        if (paid_options[traveler_id] !== undefined) {
                            paid_options[traveler_id].map((option) => {
                                if (option.option_on_group) {
                                    copy_outbound.legs.map((parsed_leg, parsed_leg_index) => {
                                        if (parsed_leg_index !== leg_index && parsed_leg.paid_options[traveler_id] !== undefined) {
                                            let copy_parsed_leg = Object.assign({}, parsed_leg);
                                            copy_parsed_leg.paid_options[traveler_id].map((paid_option, paid_option_index) => {
                                                if (paid_option.key_optional_service === option.key_optional_service) {
                                                    copy_parsed_leg.paid_options[traveler_id].splice(paid_option_index, 1);
                                                }
                                            });
                                            if (Object.keys(copy_parsed_leg.paid_options[traveler_id])) {
                                                delete copy_parsed_leg.paid_options[traveler_id];
                                            }
                                            copy_outbound.legs[parsed_leg_index] = copy_parsed_leg;
                                        }
                                    });
                                }

                                if (option.option_on_flight) {
                                    copy_flight.outbounds.map((parsed_outbound, parsed_outbound_index) => {
                                        let copy_parsed_outbound = Object.assign({}, parsed_outbound);
                                        copy_parsed_outbound.legs.map((parsed_leg, parsed_leg_index) => {
                                            if (parsed_leg.segment_key !== action.payload.segment_key && parsed_leg.key !== action.payload.segment_key && parsed_leg.paid_options[traveler_id] !== undefined) {
                                                let copy_parsed_leg = Object.assign({}, parsed_leg);
                                                copy_parsed_leg.paid_options[traveler_id].map((paid_option, paid_option_index) => {
                                                    if (paid_option.key_optional_service === option.key_optional_service) {
                                                        copy_parsed_leg.paid_options[traveler_id].splice(paid_option_index, 1);
                                                    }
                                                });
                                                if (Object.keys(copy_parsed_leg.paid_options[traveler_id])) {
                                                    delete copy_parsed_leg.paid_options[traveler_id];
                                                }
                                                copy_parsed_outbound.legs[parsed_leg_index] = copy_parsed_leg;
                                            }
                                        });
                                        copy_flight.outbounds[parsed_outbound_index] = copy_parsed_outbound;
                                    });
                                }
                            });
                            delete paid_options[traveler_id];
                            copy_leg.paid_options = paid_options;
                            copy_outbound.legs[leg_index] = copy_leg;
                            copy_flight.outbounds[action.payload.outbound_index] = copy_outbound;
                            flight = copy_flight;
                        }
                    }
                }
                copy_flights.push(flight);
            });
            new_state.flight_list_render = copy_flights;
            return new_state;
        }
        case "FLIGHT_DISPLAY_LIST": {
            new_state.flight_list_render = action.payload.list;
            // new_state.flight_list_render = getPageResults(action.payload.list, new_state.active_page);
            new_state.isUpdate = false;
            return new_state;
        }
        case "FLIGHT_UPDATE_FLIGHT_LIST": {
            new_state.flight_list_render = action.payload.flight_list;
            // new_state.flight_list_render = getPageResults(action.payload.flight_list, new_state.active_page);
            new_state.sort_value = action.payload.sort_value;
            new_state.isUpdate = false;
            return new_state;
        }
        case "FLIGHT_SET_BACKGROUND": {
            new_state.background_img = action.payload.background_img;
            return new_state;
        }
        case "FLIGHT_TOGGLE_SYNCHRONIZED_STOPOVER": {
            new_state.synchronized_stopover = !new_state.synchronized_stopover;
            return new_state;
        }
        case "FLIGHT_TOGGLE_REFUND_CONDITION": {
            new_state.refund_condition = !new_state.refund_condition;
            return new_state;
        }
        case "FLIGHT_DATE_UPDATED": {
            new_state.date_updated = action.payload.date_updated;
            return new_state;
        }
        case "FLIGHT_SHOW_PRICE_RATE": {
            new_state.show_price_rate = !new_state.show_price_rate;
            return new_state;
        }
        case "FLIGHT_TOGGLE_FLIGHT_SOURCE_NDC": {
            new_state.flight_source_ndc = !new_state.flight_source_ndc;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_FLIGHT_SOURCE_GDS": {
            new_state.flight_source_gds = !new_state.flight_source_gds;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PRIVATE_PRICE": {
            new_state.private_price = !new_state.private_price;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_TOGGLE_PUBLIC_PRICE": {
            new_state.public_price = !new_state.public_price;
            new_state.isUpdate = true;
            return new_state;
        }
        case 'FLIGHT_OPTION_CONTROL': {
            new_state.option_control = action.payload;
            return new_state;
        }
        case 'FLIGHT_SET_OPTION_LOADED': {
            new_state.option_loaded = action.payload.option_loaded;
            return new_state;
        }
        case 'FLIGHT_SET_SEATS_LOADED': {
            new_state.seats_loaded = action.payload.seats_loaded;
            return new_state;
        }
        case 'FLIGHT_RESTART_SEARCH': {
            new_state.flight_list = [];
            return new_state;
        }
        case 'FLIGHT_UPDATE_FILTERS': {
            new_state.airlines = action.payload.airlines;
            new_state.airports = action.payload.airports;
            new_state.alliances = action.payload.alliances;
            new_state.checked_luggage = action.payload.checked_luggage;
            new_state.is_day_flight = action.payload.is_day_flight;
            new_state.is_night_flight = action.payload.is_night_flight;
            new_state.private_price = action.payload.private_price;
            new_state.providers = action.payload.providers;
            new_state.public_price = action.payload.public_price;
            new_state.show_all_stop_over_duration = action.payload.show_all_stop_over_duration;
            new_state.stations = action.payload.stations;
            new_state.stop_over_active = action.payload.stop_over_active;
            new_state.stopover_airport = action.payload.stop_over_airport;
            new_state.flight_source_gds = action.payload.flight_source_gds;
            new_state.flight_source_ndc = action.payload.flight_source_ndc;
            return new_state;
        }
        case "FLIGHT_SET_SHOW_MORE_RESULTS": {
            new_state.show_more_results = action.payload;
            return new_state;
        }
        case "FLIGHT_ADD_FETCH_DATA": {
            const { alliances, airlines, airports, flight_list, max_duration, max_price, min_duration, min_price, stations, stop_over_airport, stop_over, trace_id } = action.payload;
            let new_airlines = state.airlines ? cloneDeep(state.airlines) : null;
            let new_airports = Object.assign({}, state.airports);
            let new_alliances = state.alliances ? cloneDeep(state.alliances) : null;
            let new_flight_list = cloneDeep(state.flight_list);
            let new_flight_list_render = cloneDeep(state.flight_list_render);
            let new_max_duration = state.max_duration ? state.max_duration : 0;
            let new_max_price = state.max_price ? state.max_price : 0;
            let new_min_duration = state.min_duration ? state.min_duration : 0;
            let new_min_price = state.min_price ? state.min_price : 0;
            let new_stations = Object.assign({}, state.stations);
            let new_stop_over_airport = state.stopover_airport ? cloneDeep(state.stopover_airport) : null;

            if (new_airlines) {
                if (airlines) {
                    airlines.map((airline) => {
                        if (new_airlines) {
                            let found_airline = new_airlines.find(item => item.name === airline.name);
                            if (found_airline === undefined) {
                                new_airlines.push(airline);
                            } else if (found_airline.price !== undefined && (found_airline.price === 0 || found_airline.price > airline.price)) {
                                let new_airline_index = new_airlines.findIndex(item => item.name === airline.name);
                                new_airlines[new_airline_index] = airline;
                            }
                        }
                    });
                }
            } else {
                new_airlines = airlines;
            }

            if (new_airports) {
                if (airports) {
                    Object.keys(airports).forEach(key => {
                        if (new_airports) {
                            if (new_airports[key] === undefined) {
                                new_airports[key] = airports[key];
                            } else if (new_airports[key].price === 0 || airports[key].price < new_airports[key].price) {
                                new_airports[key] = airports[key];
                            }
                        }
                    });
                }
            } else {
                new_airports = airports;
            }

            if (new_alliances) {
                if (alliances) {
                    alliances.map((alliance) => {
                        let found_alliance = new_alliances.find(item => item.name === alliance.name);
                        if (new_alliances) {
                            if (found_alliance === undefined) {
                                new_alliances.push(alliance);
                            }
                        } else if (found_alliance !== undefined && (found_alliance.price === 0 || found_alliance.price > alliance.price)) {
                            let new_alliance_index = new_alliances.findIndex(item => item.name === alliance.name);
                            new_alliances[new_alliance_index] = alliance;
                        }
                    });
                }
            } else {
                new_alliances = alliances;
            }

            if (new_flight_list) {
                if (flight_list) {
                    flight_list.map((flight) => {
                        if (flight.flight_key) {
                            if (new_flight_list.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined && !new_flight_list.some(item => CompareFlight(item, flight))) {
                                new_flight_list.push(flight);
                            }
                        } else if (!new_flight_list.some(item => CompareFlight(item, flight))) {
                            new_flight_list.push(flight);
                        }
                    });
                }
            } else {
                new_flight_list = flight_list;
            }

            if (new_flight_list_render) {
                if (flight_list) {
                    flight_list.map((flight) => {
                        let flight_luggage = null;
                        // check that every segment have same number of luggage
                        let nb_luggage_by_segment = [];
                        flight.outbounds.map((segment) => {
                            nb_luggage_by_segment.push(segment.legs[0].baggage_allowance.number_of_pieces);
                        });
                        let all_same_luggage = true;
                        nb_luggage_by_segment.map((segment_nb_luggage) => {
                            if (segment_nb_luggage !== nb_luggage_by_segment[0]) {
                                all_same_luggage = false;
                            }
                        });
                        if (all_same_luggage) {
                            flight_luggage = nb_luggage_by_segment[0];
                        }

                        if (flight.flight_key) {
                            if (new_flight_list_render.find(item => item.flight_key === flight.flight_key && item.fare_type === flight.fare_type) === undefined && !new_flight_list_render.some(item => CompareFlight(item, flight))) {
                                new_flight_list_render.push(flight);
                            }
                        } else if (!new_flight_list_render.some(item => CompareFlight(item, flight))) {
                            new_flight_list_render.push(flight);
                        }
                    });
                }
            } else {
                new_flight_list_render = flight_list;
            }

            if (max_duration) {
                new_max_duration = Math.max(new_max_duration, max_duration);
            } else {
                new_max_duration = null;
            }
            if (max_price) {
                new_max_price = Math.max(new_max_price, max_price);
            } else {
                new_max_price = null;
            }
            if (min_duration) {
                new_min_duration = Math.min(new_min_duration, min_duration);
            } else {
                new_min_duration = null;
            }
            if (min_price) {
                new_min_price = Math.min(new_min_price, min_price);
            } else {
                new_min_price = null;
            }
            if (new_stations) {
                if (stations) {
                    Object.keys(stations).forEach(key => {
                        if (new_stations) {
                            if (new_stations[key] === undefined) {
                                new_stations[key] = stations[key];
                            } else if (new_stations[key].price === 0 || stations[key].price < new_stations[key].price) {
                                new_stations[key] = stations[key];
                            }
                        }
                    });
                }
            } else {
                new_stations = stations;
            }
            if (new_stop_over_airport) {
                if (stop_over_airport) {
                    stop_over_airport.map((stop_over_outbound, outbound_index) => {
                        Object.keys(stop_over_outbound).forEach(key => {
                            if (new_stop_over_airport) {
                                if (new_stop_over_airport[outbound_index] !== undefined) {
                                    if (new_stop_over_airport[outbound_index][key] === undefined) {
                                        new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                    } else if (new_stop_over_airport[outbound_index][key].price !== undefined && stop_over_outbound.price !== undefined && new_stop_over_airport[outbound_index][key].price < stop_over_outbound[key].price) {
                                        new_stop_over_airport[outbound_index][key] = stop_over_outbound[key];
                                    }
                                }
                            }
                        });
                    });
                }
            } else {
                new_stop_over_airport = stop_over_airport;
            }

            if (new_airlines) {
                new_state.airlines = new_airlines.sort((a, b) => {
                    return (a.price - b.price);
                });
            }
            new_state.airports = new_airports;
            new_state.alliances = new_alliances;
            new_state.filter_max_duration = new_max_duration;
            if (new_max_price) {
                new_state.filter_max_price = Math.ceil(new_max_price);
                new_state.slider_max_price = Math.ceil(new_max_price);
            }
            new_state.filter_min_duration = new_min_duration;
            if (new_min_price) {
                new_state.filter_min_price = Math.floor(new_min_price);
                new_state.slider_min_price = Math.floor(new_min_price);
            }
            new_state.flight_list = new_flight_list;
            new_state.flight_list_render = new_flight_list_render;
            // new_state.flight_list_render = getPageResults(new_flight_list_render, new_state.active_page);
            new_state.max_duration = new_max_duration;
            new_state.max_price = new_max_price;
            new_state.min_duration = new_min_duration;
            new_state.min_price = new_min_price;
            new_state.slider_max_duration = new_max_duration;
            new_state.slider_min_duration = new_min_duration;
            new_state.stations = new_stations;
            new_state.stop_over = stop_over;
            new_state.stopover_airport = new_stop_over_airport;
            new_state.trace_id = trace_id;
            new_state.isUpdate = true;
            return new_state;
        }
        case "FLIGHT_OPEN_REMOVE_LUGGAGE": {
            new_state.open_remove_luggage = action.payload.open_remove_luggage;
            return new_state;
        }
        case "FLIGHT_COUNT_PROVIDER": {
            new_state.providers_count = new_state.providers_count + 1;
            return new_state;
        }
        case "FLIGHT_RESET_PROVIDER_COUNT": {
            new_state.providers_count = 0;
            new_state.providers = null;
            return new_state;
        }
        case "FLIGHT_SET_ACTIVE_PAGE": {
            const flight_list_render = new_state.flight_list;
            // const flight_list_render = getPageResults(new_state.flight_list, action.payload)
            return { ...new_state, flight_list_render: flight_list_render, active_page: action.payload };
        }
        case "FLIGHT_SET_SEATS_BY_SEGMENT": {
            let temp_seat_by_segment = cloneDeep(action.payload);
            temp_seat_by_segment.map((seat_by_segment) => {
                seat_by_segment.seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        seat.chosen = false;
                    });
                });
            });
            return {
                ...state,
                seats_by_segment: temp_seat_by_segment,
                get_seats: true
            };
        }
        case "FLIGHT_SET_SEAT_CURRENT_SEGMENT": {
            return {
                ...state,
                seat_current_segment: action.payload
            };
        }
        case "FLIGHT_SELECT_SEAT": {
            let { segment_index, traveler_id, traveler_name, seat_number, price } = action.payload;
            let copy_selected_seats = cloneDeep(state.selected_seats);
            let copy_seats_by_segments = cloneDeep(state.seats_by_segment);
            let new_traveler_seat = {
                traveler: traveler_id,
                seat_number: seat_number
            };
            let old_seat_number = "";

            if (price !== undefined) {
                new_traveler_seat.price = price;
            }

            if (copy_selected_seats[segment_index] === undefined) {
                // fill previous segments if empty
                for (let i = 0; i < segment_index; i++) {
                    if (copy_selected_seats[i] === undefined) {
                        copy_selected_seats[i] = [];
                    }
                }
                copy_selected_seats[segment_index] = [new_traveler_seat];
            } else {
                let found = false;
                let old_seat_index = null;
                copy_selected_seats[segment_index].map((segment_seats, segment_seats_index) => {
                    if (segment_seats.traveler === traveler_id) {
                        old_seat_number = segment_seats.seat_number;
                        copy_selected_seats[segment_index][segment_seats_index] = new_traveler_seat;
                        found = true;
                    } else if (segment_seats.seat_number === seat_number) {
                        old_seat_index = segment_seats_index;
                    }
                });
                if (old_seat_index) {
                    // change seat owner if same seat
                    copy_selected_seats[segment_index].splice(old_seat_index, 1);
                }
                if (!found) {
                    copy_selected_seats[segment_index].push(new_traveler_seat);
                }
            }

            if (segment_index < copy_seats_by_segments.length) {
                copy_seats_by_segments[segment_index].seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        if (seat.code === seat_number) {
                            seat.chosen = true;
                            seat.chosen_traveler = traveler_name;
                        } else if (seat.code === old_seat_number) {
                            seat.chosen = false;
                            seat.chosen_traveler = "";
                        }
                    });
                });
            }

            return {
                ...state,
                seats_by_segment: copy_seats_by_segments,
                selected_seats: copy_selected_seats
            };
        }
        case 'FLIGHT_ADD_SELECTED_PRICE': {
            const price = action.payload;
            let new_price = state.selected_price + price;
            return {
                ...state,
                selected_price: new_price
            };
        }
        case 'FLIGHT_REMOVE_SELECTED_PRICE': {
            const price = action.payload;
            let new_price = state.selected_price - price;
            return {
                ...state,
                selected_price: new_price
            };
        }
        case 'FLIGHT_RESET_SELECTED_PRICE': {
            return { ...state, selected_price: 0 };
        }
        case 'FLIGHT_RESET_SEATS': {
            return { ...state, selected_seats: [] };
        }
        case 'FLIGHT_REMOVE_SELECTED_SEAT': {
            const { segment_index, traveler_id } = action.payload;
            let copy_selected_seats = cloneDeep(state.selected_seats);
            let copy_seats_by_segments = cloneDeep(state.seats_by_segment);
            let seat_to_del = null;
            let seat_nb_to_del = null;
            // remove seat from selected seats
            if (segment_index < copy_selected_seats.length) {
                copy_selected_seats[segment_index].map((segment_seats, segment_seats_index) => {
                    if (segment_seats.traveler === traveler_id) {
                        seat_to_del = segment_seats_index;
                        seat_nb_to_del = segment_seats.seat_number;
                    }
                });
            }

            // change chosen parameters of seat_rows
            if (seat_nb_to_del && segment_index < copy_seats_by_segments.length) {
                copy_seats_by_segments[segment_index].seat_rows.map((seat_row) => {
                    seat_row.seats.map((seat) => {
                        if (seat.code === seat_nb_to_del) {
                            seat.chosen = false;
                            seat.chosen_traveler = undefined;
                        }
                    });
                });
            }
            if (seat_to_del) {
                // copy_selected_seats[segment_index] = copy_selected_seats[segment_index].filter((traveler) => traveler.traveler !== traveler_id);
                copy_selected_seats[segment_index].splice(seat_to_del, 1);
            }
            return {
                ...state,
                seats_by_segment: copy_seats_by_segments,
                selected_seats: copy_selected_seats
            };
        }
        case 'FLIGHT_RESET_DETAILED_INDEX': {
            return { ...state, detailed_index: null };
        }
        case 'FLIGHT_SET_DETAILED_INDEX': {
            return { ...state, detailed_index: action.payload };
        }
        case "FLIGHT_SET_FLIGHT_LIST": {
            new_state.flight_list = [...action.payload];
            new_state.flight_list_render = [...action.payload];
            return new_state;
        }
        case "FLIGHT_SET_MAX_MIN_DURATION": {
            return { ...new_state, slider_min_duration: action.payload.min_duration, slider_max_duration: action.payload.max_duration, is_update: true };
        }
    }
    return new_state;
}
