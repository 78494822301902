import { ItineraryInput } from "../objects/itineraryState";

type Options = {
    stepsInputs: ItineraryInput[],
    id: string | number,
    index: number
}

export function swapStepsInputsInItinerary(options: Options): void {
    const itemIndex = options.stepsInputs.findIndex((item) => {
        return item.id === options.id;
    });
    const item = options.stepsInputs[itemIndex];
    if (item) {
        if (itemIndex > options.index) {
            options.stepsInputs.splice(options.index, 0, item);
            options.stepsInputs.splice(itemIndex + 1, 1);
        } else if (itemIndex < options.index) {
            options.stepsInputs.splice(options.index, 0, item);
            options.stepsInputs.splice(itemIndex, 1);
        }
    }
}