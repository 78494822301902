const initial_state = {
    destination: null,
    destination_list: [],
    datepicker: false,
    start_date_highlight: false,
    end_date_highlight: false,
    show_language: false,
    show_menu: false,
    show_version: false,
    show_passengers: false,
    language: 'fr',
    tmp_language: 'fr',
    focus: "startDate",
    trip_version: null,
    trip_id: null,
    hover_nav: null,
    automatic_product_update: false
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.header !== undefined) {
                Object.keys(action.payload.header).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.header[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "HEADER_DESTINATION_CHANGE": {
            new_state.destination = action.payload.destination;
            return new_state;
        }
        case "HEADER_DESTINATION_LIST_CHANGE": {
            new_state.destination_list = action.payload.destination_list;
            return new_state;
        }
        case "HEADER_TOGGLE_DATEPICKER": {
            new_state.datepicker = action.payload.datepicker;
            return new_state;
        }
        case "HEADER_TOGGLE_VERSION": {
            new_state.show_version = action.payload.show_version;
            return new_state;
        }        
        case "HEADER_TOGGLE_VERSION_AND_ID": {
            new_state.show_version = action.payload.show_version;
            new_state.trip_id = action.payload.trip_id;
            new_state.trip_version = action.payload.trip_version;
            return new_state;
        }         
        case "HEADER_TOGGLE_DATES_HIGHLIGHT": {
            new_state.start_date_highlight = action.payload.start_date_highlight;
            new_state.end_date_highlight = action.payload.end_date_highlight;
            return new_state;
        }
        case "HEADER_TOGGLE_RESPONSIVE": {
            new_state.responsive = action.payload.responsive;
            return new_state;
        }
        case "HEADER_TOGGLE_DATE_FOCUS": {
            new_state.focus = action.payload.focus;
            return new_state;
        }
        case "HEADER_TOGGLE_LANGUAGE": {
            new_state.show_language = action.payload.show_language;
            return new_state;
        }
        case "HEADER_TOGGLE_MENU": {
            new_state.show_menu = action.payload.show_menu;
            return new_state;
        }
        case "HEADER_TOGGLE_PASSENGERS": {
            new_state.show_passengers = action.payload.show_passengers;
            return new_state;
        }
        case "HEADER_TOGGLE_AND_SET_LANGUAGE": {
            new_state.show_language = action.payload.show_language;
            new_state.language = action.payload.language;
            new_state.tmp_language = action.payload.language === "gb" ? "en" : action.payload.language;
            return new_state;
        }
        case "OPTIMIZATION_DESTINATION_CHANGE": {
            new_state.destination = action.payload.destination;
            new_state.destination_list = action.payload.header_destination_list;
            return new_state;
        }
        case "HEADER_SET_HOVER_NAV": {
            return {...state, hover_nav: action.payload}
        }
        case "HEADER_AUTOMATIC_PRODUCT_UPDATE": {
            return {...state, automatic_product_update: action.payload}
        }
    }
    return new_state;
}