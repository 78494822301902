import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'fr',
    debug: true,
    supportedLngs: ['en', 'fr', 'it', 'es', 'ca', 'de', 'nl', 'zh-CN', 'pt'],
    interpolation: {
        escapeValue: false // not needed for react!!
    },
    joinArrays: '\n',
    backend: {
        loadPath: `https://dev.facilitatrip.com/api/v1/translation/?lang={{lng}}`,
        // loadPath: "/Server/Locales/{{lng}}.json",
        parse: function (data) {
            const translations = JSON.parse(data);
            const first_key = Object.keys(translations)[0];
            if (first_key) {
                return translations[first_key];
            }
            return data;
        }
        // loadPath: "/Server/Locales/{{lng}}.json"
    },
    react: {
        useSuspense: true
    },
    detection: {
        order: ["localStorage", "navigator"],
        lookupQuerystring: "lng",
        lookupLocalStorage: "i18nextLng",
        caches: ["localStorage"]
    }
}, () => {
    window.moment.locale(i18n.language);
});

export default i18n;
