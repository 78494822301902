//Dependencies
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core & Lab
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingBackDrop } from '../../../Common/LoadingBackdrop';
//Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureProgress: FC = (): ReactElement => {
    const { t } = useTranslation();
    const progress = useSelector((state: AppState) => state.picture.progress);
    return (
        <LoadingBackDrop open={ progress > 0 }>
            <Stack spacing={1}>
                <LinearProgress
                    variant={ 'determinate' }
                    value={ progress }
                    sx={{ width: '100%' }}
                />
                <Typography className={ 'ft-pictureProgressHelper' }>
                    { t<string>('shared.upload-picture(s)-progress') }
                </Typography>
            </Stack>
        </LoadingBackDrop>
    );
};

export default PictureProgress;
