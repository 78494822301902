import { addProviderDates } from "../Components/CartMaterial/utils/addProviderDates";

const initial_state = {
    steps: [],
    select_all: false,
    step_info: [],
    input_focus: null,
    location_point: [],
    open_modal: false,
    agencies_modal: false,
    cars_results: [],
    cars_categories: [],
    location_index: null,
    copy_location_index: null,
    car_index: null,
    copy_car_index: null,
    total_cars: 0,
    filters_cars: [],
    display_cars: [],
    vehicles_price_error: null,
    selected_provider: null,
    selected_category: null,
    filters: {},
    selected_car: null,
    car_contract: null,
    agency_options: null,
    selected_step: null,
    show_input: true,
    cart: [],
    current_cart: [],
    request_status: null,
    car_services: null,
    agencies_info: null,
    contract_logo: null,
    important_notice: null,
    contract_status: null,
    contract_error: null,
    cars_panel: 0,
    check_availability: null,
    cart_id: null,
    cart_index: null,
    all_cars: false,
    fetch_option: null,
    filters_status: null,
    itinerary_error: false,
    detailed_index: null,
    click_type: null,
    from_cart: false,
    isForm: true,
    isResult: false,
    isContract: false,
    cars_timeline: true,
    refresh: null,
    car_contract_cart: null,
    open_itinerary: true
};
export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
	    case "persist/REHYDRATE": {
	        if (action.payload !== undefined && action.payload.cars_search !== undefined) {
	            Object.keys(action.payload.cars_search).forEach(key => {
	                if (new_state[key] !== undefined) {
	                    new_state[key] = action.payload.cars_search[key];
	                }
	            });
	        }
	        return new_state;
	    }
	    case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "CMS_FLUSH_REMOVE_STATE": {
            new_state.cart = [];
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "CARS_SET_STEPS": {
            new_state.steps = action.payload.steps;
        	return new_state;
        }
        case "CARS_SET_LOCATION_POINT": {
        	new_state.location_point = action.payload.location_point;
        	return new_state;
        }
        case "CARS_SET_SELECTED_STEP": {
            let steps = new_state.steps.slice();
            let copy_steps = [];
            steps.map((step) => {
                if (step.index === action.payload.selected_step) {
                    let copy_step = Object.assign({}, step);
                    copy_step.checked = !copy_step.checked;
                    step = copy_step;
                }
                copy_steps.push(step);
            });
            new_state.steps = copy_steps;
            return new_state;
        }
        case "CARS_SET_SELECTED_NEW_DEST": {
        	let other_choices = new_state.other_choices.slice();
        	let copy_other_choices = [];
        	other_choices.map((choice, index_choice) => {
        	    if (index_choice === action.payload.selected_step) {
        	        let copy_other_choices = Object.assign({}, choice);
        	        copy_other_choices.checked = !copy_other_choices.checked;
        	        choice = copy_other_choices;
        	    }
        	    copy_other_choices.push(choice);
        	});
        	new_state.other_choices = copy_other_choices;
        	return new_state;
        }
        case "CARS_SELECT_ALL_STEP": {
        	let steps = new_state.steps.slice();
        	let copy_steps = [];
        	steps.map((step) => {
    	        let copy_step = Object.assign({}, step);
    	        copy_step.checked = action.payload.select_all;
    	        step = copy_step;
        	    copy_steps.push(step);
        	});
        	new_state.steps = copy_steps;
        	new_state.select_all = action.payload.select_all;
        	return new_state;
        }
        case "CARS_TOGGLE_ALL_STEP" : {
        	new_state.select_all = action.payload.select_all;
        	return new_state;
        }
        case "CARS_SET_AGENCY" : {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, index_step) => {
        		if (index_step === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			if (action.payload.type === "origin") {
	        			copy_step.agency_go = action.payload.agency;
                        if (new_state.show_input) {
                            copy_step.agency_return = action.payload.agency;
                        }
                    } else {
                        copy_step.agency_return = action.payload.agency;
                    }
	        		step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_CARS_NUMBER": {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, index_step) => {
        		if (index_step === action.payload.step_index) {
        			let copy_step = Object.assign({}, step);
        			let old_cars_nbr = copy_step.car_nbr;
        			copy_step.car_nbr = action.payload.cars_nbr;
        			let copy_driver_age = copy_step.driver_age.slice();
        			if (action.payload.type) {
        				for (let i = old_cars_nbr; i < action.payload.cars_nbr; i++) {
	        				copy_driver_age.push("25");
        				}
        			} else {
                        copy_driver_age.splice(action.payload.cars_nbr, (5 - action.payload.cars_nbr));
                    }
        			copy_step.driver_age = copy_driver_age;
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_DRIVER_AGE": {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	let copy_driver_age = [];
        	step_info.map((step, index_step) => {
        		if (index_step === action.payload.step_index) {
        			let copy_step = Object.assign({}, step);
        			let driver_age = copy_step.driver_age;
        			driver_age.map((age, index_driver) => {
        				if (index_driver === action.payload.driver) {
                            age = action.payload.driver_age;
                        }
        				copy_driver_age.push(age);
        			});
        			copy_step.driver_age = copy_driver_age;
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_STEP_INFO": {
        	new_state.step_info = action.payload.step_info;
        	return new_state;
        }
        case "CARS_SET_INPUT_FOCUS": {
        	new_state.input_focus = action.payload.focus;
        	return new_state;
        }
        case "CARS_SET_HOURS" : {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, index_step) => {
        		if (index_step === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			if (action.payload.type === "origin") {
                        copy_step.origin_hours = (action.payload.hours < 10 ? "0" + (action.payload.hours).toString() : (action.payload.hours).toString());
                    } else {
                        copy_step.return_hours = (action.payload.hours < 10 ? "0" + (action.payload.hours).toString() : (action.payload.hours).toString());
                    }
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_MINUTES" : {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, index_step) => {
        		if (index_step === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			if (action.payload.type === "origin") {
                        copy_step.origin_minutes = action.payload.minutes < 10 ? "0" + (action.payload.minutes).toString() : (action.payload.minutes).toString();
                    } else {
                        copy_step.return_minutes = action.payload.minutes < 10 ? "0" + (action.payload.minutes).toString() : (action.payload.minutes).toString();
                    }
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_CHANGE_FOCUS" : {
        	let step_info = new_state.step_info;
        	let copy_info_step = [];
        	step_info.map((step, step_index) => {
        		if (step_index === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			let copy_focus = Object.assign({}, copy_step.focus);
        			if (action.payload.type === "origin") {
                        copy_focus.origin = action.payload.focus;
                    } else {
                        copy_focus.return = action.payload.focus;
                    }
        			copy_step.focus = copy_focus;
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_ORIGIN_DATE" : {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, step_index) => {
        		if (step_index === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			copy_step.origin_date = action.payload.date;
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_SET_RETURN_DATE" : {
        	let step_info = new_state.step_info.slice();
        	let copy_info_step = [];
        	step_info.map((step, step_index) => {
        		if (step_index === action.payload.index) {
        			let copy_step = Object.assign({}, step);
        			copy_step.return_date = action.payload.date;
        			step = copy_step;
        		}
        		copy_info_step.push(step);
        	});
        	new_state.step_info = copy_info_step;
        	return new_state;
        }
        case "CARS_TOGGLE_SERVICE_MODAL": {
            new_state.open_modal = action.payload.open_modal;
            return new_state;
        }
        case "CARS_TOGGLE_AGENCIES_MODAL": {
            new_state.open_agencies_modal = action.payload.open_agencies_modal;
            return new_state;
        }
        case "CARS_SET_SERVICES" : {
            new_state.car_services = action.payload.car_services;
            new_state.contract_name = action.payload.contract_name;
            new_state.contract_logo = action.payload.contract_logo;
            new_state.important_notice = action.payload.important_notice;
            new_state.open_modal = true;
            return new_state;
        }
        case "CARS_SET_AGENCIES_MODAL": {
            new_state.selected_car = action.payload.selected_car;
            new_state.open_agencies_modal = true;
            return new_state;
        }
        case "CARS_SET_AGENCY_TYPE": {
            let selected_car = Object.assign({}, new_state.selected_car);
            selected_car.agency_type = action.payload.type;
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_SET_AGENCY_LOCATION": {
            let selected_car = Object.assign({}, new_state.selected_car);
            action.payload.type === 0
                ? selected_car.location_code_pickup = action.payload.location
                : selected_car.location_code_return = action.payload.location;
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_SET_CARS_RESULTS": {
            let copy_cars_results = new_state.cars_results.slice();
            if (action.payload.cars_results !== null) {
                copy_cars_results = [].concat(copy_cars_results, action.payload.cars_results);
                copy_cars_results.sort((a, b) => {
                    return ((a.first_price < b.first_price) ? -1 : ((a.first_price > b.first_price) ? 1 : 0));
                });
            } else {
                copy_cars_results = [];
                new_state.total_cars = 0;
                new_state.all_cars = false;
            }
            new_state.cars_results = copy_cars_results;
            //new_state.filters_cars = [];
            new_state.cart_id = null;
            return new_state;
        }
        case "CARS_ALL_CARS": {
            new_state.all_cars = action.payload.flag;
            return new_state;
        }
        case "CARS_SET_LOCATION_INDEX": {
            new_state.location_index = action.payload.index;
            return new_state;
        }
        case "CARS_SET_COPY_LOCATION_INDEX": {
            new_state.copy_location_index = action.payload.index;
            return new_state;
        }
        case "CARS_SET_REDIRECTION_FLAG": {
            new_state.redirection_flag = action.payload.redirection_flag;
            return new_state;
        }
        case "CARS_CHANGE_FILTER": {
            let filters = Object.assign({}, new_state.filters);
            let copy_filters = [];
            filters[action.payload.key].map((filter, filter_index) => {
                if (filter_index === action.payload.elem_index) {
                    let copy_filter = Object.assign({}, filter);
                    copy_filter.checked = action.payload.flag;
                    filter = copy_filter;
                }
                copy_filters.push(filter);
            });
            filters[action.payload.key] = copy_filters;
            new_state.filters = filters;
            return new_state;
        }
        case "CARS_SET_ERROR": {
            let step_info = new_state.step_info.slice();
            let copy_info_step = [];
            step_info.map((step, step_index) => {
                if (step_index === action.payload.index) {
                    let copy_step = Object.assign({}, step);
                    copy_step.cars_errors = [].concat(copy_step.cars_errors, action.payload.cars_errors);
                    step = copy_step;
                }
                copy_info_step.push(step);
            });
            new_state.step_info = copy_info_step;
            return new_state;
        }
        case "CARS_REMOVE_ERROR": {
            let step_info = new_state.step_info.slice();
            let copy_step_info = [];
            step_info.map((step, step_index) => {
                if (step_index === action.payload.index) {
                    let copy_step = Object.assign({}, step);
                    copy_step.cars_errors = copy_step.cars_errors.filter((elem) => {
                        return elem !== action.payload.cars_errors;
                    });
                    step = copy_step;
                }
                copy_step_info.push(step);
            });
            new_state.step_info = copy_step_info;
            return new_state;
        }
        case "CARS_SET_AGENCY_ERROR": {
            let selected_car = Object.assign({}, new_state.selected_car);
            selected_car.error = [].concat(selected_car.error, action.payload.error);
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_REMOVE_AGENCY_ERROR": {
            let selected_car = Object.assign({}, new_state.selected_car);
            selected_car.error = [].concat(selected_car.error, action.payload.error);
            selected_car.error = selected_car.error.filter((elem) => {
                return elem !== action.payload.error;
            });
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_SET_CATEGORIES": {
            if (new_state.cars_categories !== action.payload.categories) {
                new_state.cars_categories = action.payload.categories;
            }
            return new_state;
        }
        case "CARS_SET_TOTAL_CARS": {
            new_state.total_cars += action.payload.total_cars;
            return new_state;
        }
        case "CARS_ADD_TOTAL_CARS": {
            new_state.total_cars = action.payload.total_cars;
            return new_state;
        }
        case "CARS_VEHICLES_PRICE_ERROR": {
            new_state.vehicles_price_error = action.payload.error;
            return new_state;
        }
        case "CARS_SET_FILTERS": {
            new_state.filters = action.payload.filters;
            return new_state;
        }
        case "CARS_DISPLAY_CARS": {
            new_state.display_cars = action.payload.display_cars;
            return new_state;
        }
        case "CARS_FILTERS_CARS": {
            let cars_results = new_state.cars_results.slice();
            let filters_cars = [];
            if (action.payload.category !== null && action.payload.provider !== null) {
                cars_results[action.payload.provider].vehicles[action.payload.category].map((vehicles) => {
                    if (vehicles.station_prices !== null) {
                        filters_cars.push(vehicles);
                    }
                });
            } else if (action.payload.category !== null && action.payload.provider === null) {
                cars_results.map((provider) => {
                    let prices = true;
                    if (provider.vehicles[action.payload.category] !== undefined) {
                        provider.vehicles[action.payload.category].map((category) => {
                            if (category.station_prices === null) {
                                prices = false;
                            }
                        });
                        if (prices) {
                            filters_cars = [].concat(filters_cars, provider.vehicles[action.payload.category]);
                        }
                    }
                });
            } else if (action.payload.category === null && action.payload.provider !== null) {
                let provider = null;
                if (action.payload.sub_name === cars_results[action.payload.provider].subprovider_name) {
                    provider = cars_results[action.payload.provider].vehicles;
                }
                Object.keys(provider).map((category) => {
                    provider[category].map((vehicle) => {
                        if (vehicle.station_prices !== null) {
                            filters_cars = [].concat(filters_cars, vehicle);
                        }
                    });
                });
            } else if (action.payload.category === null && action.payload.provider === null) {
                let tmp = [];
                cars_results.map((providers) => {
                    let provider = providers.vehicles;
                    Object.keys(provider).map((category) => {
                        provider[category].map((vehicle) => {
                            if (vehicle.station_prices !== null) {
                                tmp = [].concat(tmp, vehicle);
                                filters_cars = [].concat(filters_cars, vehicle);
                            }
                        });
                    });
                });
                console.log("tmp:", tmp);
            }
            new_state.selected_provider = action.payload.provider;
            new_state.selected_category = action.payload.category;
            new_state.filters_cars = filters_cars.sort((a, b) => (parseFloat(a.station_prices[0].prices[0].selling_price) > parseFloat(b.station_prices[0].prices[0].selling_price)) ? 1 : ((parseFloat(b.station_prices[0].prices[0].selling_price) > parseFloat(a.station_prices[0].prices[0].selling_price)) ? -1 : 0));
            new_state.display_cars = new_state.filters_cars;
            new_state.filters_status = "update";
            return new_state;
        }
        case "CARS_SELECTED_CAR": {
            new_state.selected_car = action.payload.selected_car;
            new_state.car_contract = null;
            return new_state;
        }
        case "CARS_SET_CONTRACT": {
            let selected_car = Object.assign({}, new_state.selected_car);
            if (action.payload.contract !== null) {
                action.payload.contract.contracts.map((contract) => {
                    if (action.payload.contract.selected === contract.contract_id) {
                        selected_car.contract = contract.contract;
                        selected_car.contract_name = contract.name;
                        selected_car.contract_logo = contract.logo;
                    }
                });
            }
            new_state.contract_status = "fetched";
            new_state.selected_car = selected_car;
            new_state.car_contract = action.payload.contract;
            if (new_state.car_contract_cart !== null) {
                let car_contract = Object.assign({}, new_state.car_contract);
                let selected_car = Object.assign({}, new_state.selected_car);
                car_contract.contracts.map((contract) => {
                    if (contract.name === new_state.car_contract_cart) {
                        car_contract.selected = contract.contract_id;
                        let station_prices = selected_car.station_prices ? selected_car.station_prices.slice() : null;
                        selected_car.contract = contract.contract;
                        selected_car.contract_name = contract.name;
                        selected_car.contract_logo = contract.logo;
                        if (station_prices) {
                            let tmp_station_prices = station_prices.slice();
                            let cpy_station = [];
                            tmp_station_prices.map((station) => {
                                if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station)) {
                                    let obj_station = Object.assign({}, station);
                                    obj_station.prices = contract.prices;
                                    station = obj_station;
                                }
                                cpy_station.push(station);
                            });
                            station_prices = cpy_station;
                        } else {
                            selected_car.selling_price = contract.prices[0];
                        }
                        selected_car.station_prices ? selected_car.station_prices = station_prices : "";
                    }
                });
                new_state.car_contract = car_contract;
                new_state.car_contract_cart = null;
                new_state.selected_car = selected_car;
            }
            return new_state;
        }
        case "CARS_CONTRACT_ERROR": {
            new_state.contract_error = action.payload.contract_error;
            return new_state;
        }
        case "CARS_SELECTED_CONTRACT": {
            let car_contract = Object.assign({}, new_state.car_contract);
            let selected_car = Object.assign({}, new_state.selected_car);
            car_contract.selected = action.payload.contract_id;
            car_contract.contracts.map((contract) => {
                if (contract.contract_id === action.payload.contract_id) {
                    let station_prices = selected_car.station_prices ? selected_car.station_prices.slice() : null;
                    selected_car.contract = contract.contract;
                    selected_car.contract_name = contract.name;
                    selected_car.contract_logo = contract.logo;
                    if (station_prices) {
                        let tmp_station_prices = station_prices.slice();
                        let cpy_station = [];
                        tmp_station_prices.map((station) => {
                            if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station)) {
                                let obj_station = Object.assign({}, station);
                                obj_station.prices = contract.prices;
                                station = obj_station;
                            }
                            cpy_station.push(station);
                        });
                        station_prices = cpy_station;
                    } else {
                        selected_car.selling_price = contract.prices[0];
                    }
                    selected_car.station_prices ? selected_car.station_prices = station_prices : "";
                }
            });
            new_state.car_contract = car_contract;
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_SET_CARS_CART" : {
            new_state.cart = action.payload.cars.map((item) => {
                return addProviderDates(item);
            });
            if (new_state.refresh !== null) {
                let cart = [...new_state.cart];
                Object.keys(new_state.refresh).map(key => {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i].id === parseInt(key)) {
                            let car = { ...cart[i] };
                            car.price_change = new_state.refresh[key];
                            cart[i] = car;
                            break;
                        }
                    }
                });
                new_state.cart = cart;
            }
            return new_state;
        }
        case "CARS_ADD_TO_CART": {
            new_state.cart = [].concat(new_state.cart, addProviderDates(action.payload.car));
            new_state.prev_id = null;
            return new_state;
        }
        case 'CARS_UPDATE_CART': {
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_car => {
                        if (cart[i].id === new_car.id) {
                            cart[i] = addProviderDates(new_car);
                        }
                    });
                });
            }
            new_state.cart = cart;
            return new_state;
        }
        case "CARS_SET_MANUAL_MARGIN_EDIT" : {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "CARS_REMOVE_CART": {
            let copy_cart = new_state.cart.slice();
            copy_cart.splice(action.payload.car_index, 1);
            new_state.cart = copy_cart;
            return new_state;
        }
        case "CARS_REMOVE_CURRENT_CART": {
            let copy_current_cart = new_state.current_cart.slice();
            copy_current_cart.splice(action.payload.car_index, 1);
            new_state.current_cart = copy_current_cart;
            return new_state;
        }
        case "CARS_SET_AGENCY_OPTIONS": {
            new_state.agency_options = action.payload.agency_options;
            return new_state;
        }
        case "CARS_SELECT_AGENCY_OPTIONS": {
            let agency_options = new_state.agency_options.slice();
            let selected_car = Object.assign({}, new_state.selected_car);
            let copy_agency_options = [];
            agency_options.map((option, option_index) => {
                if (action.payload.option_index === option_index) {
                    let copy_option = Object.assign({}, option);
                    copy_option.check = action.payload.flag;
                    if (action.payload.flag) {
                        selected_car.special_equipments = [].concat(selected_car.special_equipments, option);
                    } else {
                        selected_car.special_equipments = selected_car.special_equipments.filter((special_equipment) => {
                            return special_equipment.code_id !== action.payload.option_id;
                        });
                    }
                    option = copy_option;
                }
                copy_agency_options.push(option);
            });
            new_state.agency_options = copy_agency_options;
            new_state.selected_car = selected_car;
            return new_state;
        }
        case "CARS_SELECTED_STEP": {
            new_state.selected_step = action.payload.selected_step;
            return new_state;
        }
        case "CARS_SHOW_INPUT": {
            new_state.show_input = action.payload.show_input;
            return new_state;
        }
        case "CARS_SET_CAR_INDEX": {
            new_state.car_index = action.payload.car_index;
            return new_state;
        }
        case "CARS_SET_COPY_CAR_INDEX": {
            new_state.copy_car_index = action.payload.car_index;
            return new_state;
        }
        case "CARS_ADD_CAR_TO_CURRENT_CART": {
            let current_cart = new_state.current_cart.slice();
            if (action.payload.contract) {
                let array_service = [];
                action.payload.contract.services.map((service) => {
                    if (service.contracts.includes(action.payload.contract.selected)) {
                        array_service.push(service);
                    }
                });
                action.payload.car.forfait_service = array_service;
                action.payload.contract.contracts.map((contract) => {
                    if (action.payload.contract.selected === contract.contract_id) {
                        action.payload.car.contract = contract;
                    }
                });
            }
            current_cart.push(action.payload.car);
            new_state.current_cart = current_cart;
            return new_state;
        }
        case "CARS_RESET_CURRENT_CART": {
            new_state.current_cart = [];
            return new_state;
        }
        case "CARS_REQUEST_STATUS": {
            new_state.request_status = action.payload.status;
            return new_state;
        }
        case "CARS_SET_OPTION_QUANTITY": {
            let agency_options = new_state.agency_options.slice();
            let selected_car = Object.assign({}, new_state.selected_car);
            let copy_agency_options = [];
            let copy_special_equipments = [];
            agency_options.map((option) => {
                if (option.code_id === action.payload.option_id) {
                    let copy_option = Object.assign({}, option);
                    copy_option.quantity = action.payload.quantity;
                    option = copy_option;
                }
                copy_agency_options.push(option);
            });
            copy_agency_options.map((option) => {
                if (option.quantity > 0) {
                    copy_special_equipments.push(option);
                }
            });
            selected_car.special_equipments = copy_special_equipments;
            new_state.selected_car = selected_car;
            new_state.agency_options = copy_agency_options;
            return new_state;
        }
        case "CARS_RESET_CONTRACT_STATUS": {
            new_state.contract_status = null;
            return new_state;
        }
        case "CARS_SET_CARS_PANEL": {
            new_state.cars_panel = action.payload.cars_panel;
            return new_state;
        }
        case "CARS_CHECK_AVAILABILITY_STATUS": {
            new_state.check_availability = action.payload.status;
            return new_state;
        }
        case "CARS_SET_READ_ONLY": {
            let step_info = new_state.step_info.slice();
            let copy_step_info = [];
            step_info.map((step, index) => {
                if (index === action.payload.index) {
                    let copy_step = Object.assign({}, step);
                    action.payload.type === "departure" ? copy_step.readOnly_departure = action.payload.flag : copy_step.readOnly_arrival = action.payload.flag;
                    action.payload.type === "departure" ? copy_step.agency_go = null : copy_step.agency_return = null;
                    step = copy_step;
                }
                copy_step_info.push(step);
            });
            new_state.step_info = copy_step_info;
            return new_state;
        }
        case "CARS_SET_OPTION_STATUS": {
            new_state.fetch_option = action.payload.status;
            return new_state;
        }
        case "CARS_CHANGE_CAR_PACK": {
            new_state.step_info = action.payload.step_info;
            new_state.selected_car = action.payload.selected_car;
            new_state.cart_id = action.payload.cart_id;
            new_state.cart_index = action.payload.cart_index;
            new_state.fetch_option = action.payload.status;
            new_state.agency_options = action.payload.special_equipments;
            new_state.from_cart = action.payload.from_cart;
            new_state.car_contract_cart = action.payload.contract_name;
            new_state.location_index = 0;
            new_state.car_index = 0;
            new_state.car_contract = null;
            new_state.cars_results = [];
            new_state.current_cart = [];
            return new_state;
        }
        case "CARS_PACK_ERROR": {
            new_state.fetch_option = null;
            return new_state;
        }
        case "CARS_INIT_CARS_RESULTS": {
            new_state.vehicles_price_error = null;
            new_state.contract_error = null;
            new_state.cars_results = [];
            new_state.display_cars = [];
            new_state.filters_cars = [];
            new_state.cars_categories = [];
            new_state.filters = [];
            new_state.total_cars = 0;
            new_state.all_cars = false;
            new_state.cart_id = null;
            new_state.car_contract = null;
            new_state.filters_status = null;
            new_state.selected_category = null;
            new_state.selected_provider = null;
            new_state.check_availability = null;
            new_state.copy_location_index = action.payload.index_location;
            new_state.copy_car_index = action.payload.car_index;
            return new_state;
        }
        case "CARS_CLEAR_DATA": {
            new_state.vehicles_price_error = null;
            new_state.contract_error = null;
            new_state.display_cars = [];
            new_state.filters_cars = [];
            new_state.car_contract = null;
            new_state.filters_status = null;
            new_state.selected_category = null;
            new_state.selected_provider = null;
            return new_state;
        }
        case "CARS_TOGGLE_DETAIL": {
            let display_cars = new_state.display_cars;
            let copy_card = [];
            display_cars.map((card, index_card) => {
                if (index_card === action.payload.index) {
                    let cpy_card = Object.assign({}, card);
                    cpy_card.detail = !cpy_card.detail;
                    card = cpy_card;
                }
                copy_card.push(card);
            });
            new_state.display_cars = copy_card;
            return new_state;
        }
        case "CARS_TOGGLE_PRE_DETAIL": {
            let current_cart = new_state.current_cart;
            let copy_card = [];
            current_cart.map((card, index_card) => {
                if (index_card === action.payload.index) {
                    let cpy_card = Object.assign({}, card);
                    cpy_card.detail = !cpy_card.detail;
                    card = cpy_card;
                }
                copy_card.push(card);
            });
            new_state.current_cart = copy_card;
            return new_state;
        }
        case "CARS_TOGGLE_CART_DETAIL": {
            let cart = new_state.cart;
            let copy_card = [];
            cart.map((card, index_card) => {
                if (index_card === action.payload.index) {
                    let cpy_card = Object.assign({}, card);
                    cpy_card.detail = !cpy_card.detail;
                    card = cpy_card;
                }
                copy_card.push(card);
            });
            new_state.cart = copy_card;
            return new_state;
        }
        case "CARS_ITINERARY_ERROR": {
            new_state.itinerary_error = action.payload.itinerary_error;
            return new_state;
        }
        case "CARS_SET_DETAIL": {
            new_state.detailed_index = action.payload.detailed_index;
            new_state.click_type = action.payload.type;
            return new_state;
        }
        case "CARS_EDIT_CART": {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((car) => {
                if (action.payload.car.id === car.id) {
                    copy_cart.push(action.payload.car);
                } else {
                    copy_cart.push(car);
                }
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "CARS_TOGGLE_FROM_CART": {
            new_state.from_cart = action.payload.from_cart;
            return new_state;
        }
        case "CARS_IS_FORM": {
            new_state.isForm = true;
            new_state.isContract = false;
            new_state.isResult = false;
            new_state.all_cars = false;
            if (action.payload !== undefined) {
                new_state.from_cart = action.payload.from_cart;
            }
            return new_state;
        }
        case "CARS_IS_RESULT": {
            new_state.isForm = false;
            new_state.isContract = false;
            new_state.isResult = true;
            new_state.car_contract = null;
            return new_state;
        }
        case "CARS_IS_CONTRACT": {
            new_state.isForm = false;
            new_state.isContract = true;
            new_state.isResult = false;
            return new_state;
        }
        case "CARS_TOGGLE_TIMELINE": {
            new_state.cars_timeline = action.payload.cars_timeline;
            return new_state;
        }
        case "CARS_RESET_RESULTS": {
            new_state.cars_results = [];
            new_state.cars_categories = [];
            return new_state;
        }
        case 'CAR_REMOVE_FROM_CART_BY_ID': {
            let cart = new_state.cart.slice();
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === action.payload) {
                    cart.splice(i, 1);
                    break;
                }
            }
            new_state.cart = cart;
            return new_state;
        }
        case 'CAR_EDIT_CART_BY_ID': {
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === action.payload.id) {
                    cart[i] = addProviderDates(action.payload);
                    break;
                }
            }
            new_state.cart = cart;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            if (action.payload !== null) {
                new_state.refresh = action.payload.cars;
            }
            return new_state;
        }
        case 'CAR_OPEN_ITINERARY': {
            return {...new_state, open_itinerary: !new_state.open_itinerary}
        }
    }
    return new_state;
}
