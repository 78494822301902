// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Button from '@mui/material/Button';
// Actions
import { addSelection, deleteSelection } from './redux/actions';
// Utils
import { PictureContext } from './utils/pictureContext';
// Types
import { AppState } from '../../../../Reducers/Reducers';
import { Picture } from './objects/picture';

type PictureModalCreateTabsProps = {
    picture: Picture
};

const PictureModalListActions: FC<PictureModalCreateTabsProps> = ({ picture }): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selection = useSelector((state: AppState) => state.picture.selection);
    const files = useSelector((state: AppState) => state.picture.files);
    let added = false;
    for (let i = 0; i < selection.length; i++) {
        if (selection[i]?.id === picture.id) {
            added = true;
            break;
        }
    }
    const onAddPicture = () => {
        dispatch(addSelection(picture));
    };
    const onDeletePicture = () => {
        dispatch(deleteSelection(picture.id));
    };
    if (added) {
        return <Button className={ 'ft-redText' } onClick={ onDeletePicture }>{ t<string>('shared.delete-from-selection') }</Button>;
    }
    return (
        <PictureContext.Consumer>
            {({ singleFile }) => (
                <Button
                    onClick={ onAddPicture }
                    disabled={singleFile && (selection.length > 0 || files.length > 0)}
                >
                    { t<string>('shared.add-to-selection') }
                </Button>
            )}
        </PictureContext.Consumer>
    );
};

export default PictureModalListActions;
