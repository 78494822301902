//Dependencies
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';
import {
    AtomicBlockUtils,
    Editor,
    EditorState,
    RichUtils,
    DraftHandleValue,
    ContentBlock
} from 'draft-js';
//Core & Lab
import Grid from '@mui/material/Grid';
import decorator from './decorator';
import { RichEditorFontSizeStylesMap } from './richEditorFontSizeControl';
import { EditorToolbar, EditorToolbarProps } from './editorToolbar';
import { customColors } from './customColors';
import { renderRichEditorContentHTML } from './renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from './restoreRichEditorStateFromHtml';
import { Picture } from '../../picture/objects/picture';
import { AppState } from '../../../../../Reducers/Reducers';

export const RichEditorStyleMap: {[index: string]: React.CSSProperties} = {
    STRIKETHROUGH: {
        textDecoration: 'line-through'
    },
    UNDERLINE: {
        textDecoration: 'underline'
    },
    COLOR_BLACK: {
        color: '#000000'
    },
    COLOR_DARK_GRAY_4: {
        color: '#434343'
    },
    COLOR_DARK_GRAY_3: {
        color: '#666666'
    },
    COLOR_DARK_GRAY_2: {
        color: '#999999'
    },
    COLOR_DARK_GRAY_1: {
        color: '#B7B7B7'
    },
    COLOR_GRAY: {
        color: '#CCCCCC'
    },
    COLOR_LIGHT_GRAY_1: {
        color: '#D9D9D9'
    },
    COLOR_LIGHT_GRAY_2: {
        color: '#EFEFEF'
    },
    COLOR_LIGHT_GRAY_3: {
        color: '#F3F3F3'
    },
    COLOR_WHITE: {
        color: '#FFFFFF'
    },
    COLOR_RED_BERRY: {
        color: '#980000'
    },
    COLOR_RED: {
        color: '#FF0000'
    },
    COLOR_ORANGE: {
        color: '#FF9900'
    },
    COLOR_YELLOW: {
        color: '#FFFF00'
    },
    COLOR_GREEN: {
        color: '#00FF00'
    },
    COLOR_CYAN: {
        color: '#00FFFF'
    },
    COLOR_CORNFLOWER_BLUE: {
        color: '#4A86E8'
    },
    COLOR_BLUE: {
        color: '#0000FF'
    },
    COLOR_PURPLE: {
        color: '#9900FF'
    },
    COLOR_MAGENTA: {
        color: '#FF00FF'
    },
    COLOR_LIGHT_RED_BERRY_3: {
        color: '#E6B8AF'
    },
    COLOR_LIGHT_RED_3: {
        color: '#F4CCCC'
    },
    COLOR_LIGHT_ORANGE_3: {
        color: '#FCE5CD'
    },
    COLOR_LIGHT_YELLOW_3: {
        color: '#FFF2CC'
    },
    COLOR_LIGHT_GREEN_3: {
        color: '#D9EAD3'
    },
    COLOR_LIGHT_CYAN_3: {
        color: '#D0E0E3'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_3: {
        color: '#C9DAF8'
    },
    COLOR_LIGHT_BLUE_3: {
        color: '#CFE2F3'
    },
    COLOR_LIGHT_PURPLE_3: {
        color: '#D9D2E9'
    },
    COLOR_LIGHT_MAGENTA_3: {
        color: '#EAD1DC'
    },
    COLOR_LIGHT_RED_BERRY_2: {
        color: '#DD7E6B'
    },
    COLOR_LIGHT_RED_2: {
        color: '#EA9999'
    },
    COLOR_LIGHT_ORANGE_2: {
        color: '#F9CB9C'
    },
    COLOR_LIGHT_YELLOW_2: {
        color: '#FFE599'
    },
    COLOR_LIGHT_GREEN_2: {
        color: '#B6D7A8'
    },
    COLOR_LIGHT_CYAN_2: {
        color: '#A2C4C9'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_2: {
        color: '#A4C2F4'
    },
    COLOR_LIGHT_BLUE_2: {
        color: '#9FC5E8'
    },
    COLOR_LIGHT_PURPLE_2: {
        color: '#B4A7D6'
    },
    COLOR_LIGHT_MAGENTA_2: {
        color: '#D5A6BD'
    },
    COLOR_LIGHT_RED_BERRY_1: {
        color: '#CC4125'
    },
    COLOR_LIGHT_RED_1: {
        color: '#E06666'
    },
    COLOR_LIGHT_ORANGE_1: {
        color: '#F6B26B'
    },
    COLOR_LIGHT_YELLOW_1: {
        color: '#FFD966'
    },
    COLOR_LIGHT_GREEN_1: {
        color: '#93C47D'
    },
    COLOR_LIGHT_CYAN_1: {
        color: '#76A5AF'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_1: {
        color: '#6D9EEB'
    },
    COLOR_LIGHT_BLUE_1: {
        color: '#6FA8DC'
    },
    COLOR_LIGHT_PURPLE_1: {
        color: '#8E7CC3'
    },
    COLOR_LIGHT_MAGENTA_1: {
        color: '#C27BA0'
    },
    COLOR_DARK_RED_BERRY_1: {
        color: '#A61C00'
    },
    COLOR_DARK_RED_1: {
        color: '#CC0000'
    },
    COLOR_DARK_ORANGE_1: {
        color: '#E69138'
    },
    COLOR_DARK_YELLOW_1: {
        color: '#F1C232'
    },
    COLOR_DARK_GREEN_1: {
        color: '#6AA84F'
    },
    COLOR_DARK_CYAN_1: {
        color: '#45818E'
    },
    COLOR_DARK_CORNFLOWER_BLUE_1: {
        color: '#3C78D8'
    },
    COLOR_DARK_BLUE_1: {
        color: '#3D85C6'
    },
    COLOR_DARK_PURPLE_1: {
        color: '#674EA7'
    },
    COLOR_DARK_MAGENTA_1: {
        color: '#A64D79'
    },
    COLOR_DARK_RED_BERRY_2: {
        color: '#85200C'
    },
    COLOR_DARK_RED_2: {
        color: '#990000'
    },
    COLOR_DARK_ORANGE_2: {
        color: '#B45F06'
    },
    COLOR_DARK_YELLOW_2: {
        color: '#BF9000'
    },
    COLOR_DARK_GREEN_2: {
        color: '#38761D'
    },
    COLOR_DARK_CYAN_2: {
        color: '#134F5C'
    },
    COLOR_DARK_CORNFLOWER_BLUE_2: {
        color: '#1155CC'
    },
    COLOR_DARK_BLUE_2: {
        color: '#0B5394'
    },
    COLOR_DARK_PURPLE_2: {
        color: '#351C75'
    },
    COLOR_DARK_MAGENTA_2: {
        color: '#741B47'
    },
    COLOR_DARK_RED_BERRY_3: {
        color: '#5B0F00'
    },
    COLOR_DARK_RED_3: {
        color: '#660000'
    },
    COLOR_DARK_ORANGE_3: {
        color: '#783F04'
    },
    COLOR_DARK_YELLOW_3: {
        color: '#7F6000'
    },
    COLOR_DARK_GREEN_3: {
        color: '#274E13'
    },
    COLOR_DARK_CYAN_3: {
        color: '#0C343D'
    },
    COLOR_DARK_CORNFLOWER_BLUE_3: {
        color: '#1C4587'
    },
    COLOR_DARK_BLUE_3: {
        color: '#073763'
    },
    COLOR_DARK_PURPLE_3: {
        color: '#20124D'
    },
    COLOR_DARK_MAGENTA_3: {
        color: '#4C1130'
    }
};

type RichEditorProps = {
    pictures: null | Picture[],
    editorState: EditorState,
    setEditorState: any,
    action?: (locale: number, html: string) => {type: string} & unknown,
    values: Record<string, unknown> & {[index: string]: string},
    contentContainerStyles?: React.CSSProperties,
    noDefault?: boolean,
    controlledState?: boolean,
    children?: (editor: JSX.Element) => JSX.Element,
    readOnly?: boolean
} & EditorToolbarProps

const RichEditor = React.forwardRef<Editor, RichEditorProps>(({
    pictures,
    editorState,
    setEditorState,
    action,
    values,
    contentContainerStyles,
    hideToolbar,
    noDefault,
    controlledState,
    children,
    toolbars,
    centerToolbars,
    readOnly
}, editorRef): ReactElement => {
    const dispatch = useDispatch();
    const current_locale = useSelector((state: AppState) => state.locale.current_locale);
    const previous_locale = useSelector((state: AppState) => state.locale.previous_locale);
    const quotationCode = useSelector((state: AppState) => state.header.quotation_code);
    const styles = {
        ...RichEditorStyleMap,
        ...(quotationCode ? customColors[quotationCode] : null)
    };

    const handleKeyCommand = (command: any): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const Image = (props: { src: string | undefined; }) => {
        return <img src={ props.src } width={ 150 }/>;
    };

    const Media = (props: { contentState: { getEntity: (arg0: any) => any; }; block: { getEntityAt: (arg0: number) => any; }; }) => {
        const entity = props.contentState.getEntity(props.block.getEntityAt(0));
        const type = entity.getType();
        let media;
        if (type === 'image') {
            const { src } = entity.getData();
            media = <Image src={ src }/>;
        }
        return media;
    };

    const mediaBlockRenderer = (block: { getType: () => string; }) => {
        if (block.getType() === 'atomic') {
            return {
                component: Media,
                editable: false
            };
        }
    };

    const onSave = (save_locale: null | number) => () => {
        if (save_locale !== null) {
            const html = renderRichEditorContentHTML(
                styles,
                editorState.getCurrentContent()
            );

            if (action) {
                dispatch(action(save_locale, html));
            }
        }
    };

    useEffect(() => {
        if (pictures !== null && pictures.length > 0) {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                'image',
                'MUTABLE',
                {
                    src: pictures[0]?.url,
                    alt: pictures[0]?.name !== null ? pictures[0]?.name : '',
                    alignment: 'default'
                }
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(
                editorState,
                { currentContent: contentStateWithEntity }
            );
            setEditorState(AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                ' '
            ));
        }
    }, [pictures]);

    useEffect(() => {
        if (current_locale !== null && !controlledState) {
            if (values[current_locale]) {
                const contentState = restoreRichEditorStateFromHtml(
                    styles,
                    values[current_locale] ?? ''
                );
                setEditorState(contentState);
            } else {
                setEditorState(EditorState.createEmpty(decorator));
            }
            onSave(previous_locale);
        }
    }, [current_locale]);

    return (
        <Grid container>
            <EditorToolbar
                editorState={editorState}
                setEditorState={setEditorState}
                hideToolbar={hideToolbar}
                centerToolbars={centerToolbars}
                toolbars={toolbars}
            />
            <Grid
                item
                xs={ 12 }
            >
                {
                    children ?
                        children(
                            <Editor
                                customStyleMap={{
                                    ...styles,
                                    ...RichEditorFontSizeStylesMap
                                }}
                                blockRendererFn={ mediaBlockRenderer }
                                blockStyleFn={BlockStyleFn}
                                editorState={ editorState }
                                handleKeyCommand={ handleKeyCommand }
                                onChange={ setEditorState }
                                ref={ editorRef }
                                readOnly={readOnly}
                                spellCheck
                            />
                        ) :
                        <div
                            className={ 'ft-editor' }
                            onBlur={ onSave(current_locale) }
                            style={{
                                ...(
                                    noDefault ?
                                        {
                                            padding: 0,
                                            border: 'none',
                                            minHeight: 0
                                        } :
                                        undefined
                                ),
                                ...contentContainerStyles
                            }}
                        >
                            <Editor
                                customStyleMap={{
                                    ...styles,
                                    ...RichEditorFontSizeStylesMap
                                }}
                                blockRendererFn={ mediaBlockRenderer }
                                blockStyleFn={BlockStyleFn}
                                editorState={ editorState }
                                handleKeyCommand={ handleKeyCommand }
                                onChange={ setEditorState }
                                ref={ editorRef }
                                spellCheck
                            />
                        </div>
                }
            </Grid>
        </Grid>
    );
});

function BlockStyleFn(block: ContentBlock): string {
    const alignment = block.getData().get('alignment');
    if (alignment) {
        return `ft-align-${alignment}`;
    }
    return '';
}

export default RichEditor;
