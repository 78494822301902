import { Itinerary } from "../objects/itinerary";

export function sortItinerary(
    a: Pick<Itinerary, 'step_type' | 'start_date' | 'end_date' | 'r2r_json'>,
    b: Pick<Itinerary, 'step_type' | 'start_date' | 'end_date' | 'r2r_json'>
): number {
    if (a.step_type === 'START') {
        return -1;
    } else if (a.step_type === 'END') {
        return 1;
    } else if (b.step_type === 'START') {
        return 1;
    } else if (b.step_type === 'END') {
        return -1;
    }

    const aDelta = window.moment.utc(
        b.start_date
    ).diff(
        window.moment.utc(a.end_date),
        'minutes'
    );
    const bDelta = window.moment.utc(
        a.start_date
    ).diff(
        window.moment.utc(b.end_date),
        'minutes'
    );

    if (aDelta === a.r2r_json?.duration) {
        return -1;
    } else if (bDelta === b.r2r_json?.duration) {
        return 1;
    }

    const aDate = window.moment.utc(a.start_date);
    const bDate = window.moment.utc(b.start_date);
    return aDate.isBefore(window.moment.utc(bDate)) ?
        -1 :
        1;
}
