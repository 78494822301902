import { ItineraryInput } from "../objects/itineraryState";
import { transformStepInputsToGroups } from "./transformStepInputsToGroups";

type Options = {
    stepsInputs: ItineraryInput[],
    group: number,
    index: number
}

export function swapBlockInItinerary(options: Options): ItineraryInput[] {
    const groups = transformStepInputsToGroups(options.stepsInputs);
    const group = groups[options.group] ?? [];
    const groupIds = group.map((item) => item.id);
    const firstGroupElementIndex = options.stepsInputs.findIndex((item) => {
        return item.circuit && item.circuit === group[0]?.circuit;
    });

    const result: ItineraryInput[] = [];
    //a swap from bottom to top
    if (firstGroupElementIndex > options.index) {
        for (let i = 0; i < options.index; i++) {
            result.push(options.stepsInputs[i]!);
        }
        result.push(...group);
        result.push(
            ...options.stepsInputs.slice(options.index).filter((item) => {
                return !groupIds.includes(item.id);
            })
        );
        //a swap from top to bottom
    } else {
        result.push(
            ...options.stepsInputs.slice(0, options.index).filter((item) => {
                return !groupIds.includes(item.id);
            })
        );
        result.push(...group);
        for (let i = options.index; i < options.stepsInputs.length; i++) {
            result.push(options.stepsInputs[i]!);
        }
    }

    return result;
}
