import { List } from '../objects/list';
import { Picture } from '../objects/picture';
import { PictureType } from '../objects/pictureState';

export const ADD_CUSTOM_PICTURES = 'PICTURE_ADD_CUSTOM_PICTURES';
export const ADD_FILES = 'PICTURE_ADD_FILES';
export const ADD_SELECTION = 'PICTURE_ADD_SELECTION';
export const DELETE_FILE = 'PICTURE_DELETE_FILE';
export const DELETE_SELECTION = 'PICTURE_DELETE_SELECTION';
export const RESET_CREATE_FORM = 'PICTURE_RESET_CREATE_FORM';
export const SET_ADDING = 'PICTURE_SET_ADDING';
export const SET_CREATE_ALT = 'PICTURE_SET_CREATE_ALT';
export const SET_CREATE_CREDIT = 'PICTURE_SET_CREATE_CREDIT';
export const SET_CREATE_DESCRIPTION = 'PICTURE_SET_CREATE_DESCRIPTION';
export const SET_CREATE_NAME = 'PICTURE_SET_CREATE_NAME';
export const SET_CREATE_TYPE = 'PICTURE_SET_CREATE_TYPE';
export const SET_CROPPER = 'PICTURE_SET_CROPPER';
export const SET_LIMIT = 'PICTURE_SET_LIMIT';
export const SET_LIST = 'PICTURE_SET_LIST';
export const SET_PAGE = 'PICTURE_SET_PAGE';
export const SET_PICTURE = 'PICTURE_SET_PICTURE';
export const SET_QUERY = 'PICTURE_SET_QUERY';
export const SET_RUNNING = 'PICTURE_SET_RUNNING';
export const SET_SEARCH = 'PICTURE_SET_SEARCH';
export const TOGGLE_CREATE = 'PICTURE_TOGGLE_CREATE';
export const TOGGLE_FILTER = 'PICTURE_TOGGLE_FILTER';
export const TOGGLE_KEEP = 'PICTURE_TOGGLE_KEEP';
export const TOGGLE_REFRESH = 'PICTURE_TOGGLE_REFRESH';
export const UPDATE_ALT = 'PICTURE_UPDATE_ALT';
export const UPDATE_CREATE_TAB = 'PICTURE_UPDATE_CREATE_TAB';
export const UPDATE_CREDITS = 'PICTURE_UPDATE_CREDITS';
export const UPDATE_DESCRIPTION = 'PICTURE_UPDATE_DESCRIPTION';
export const UPDATE_FILTER_TYPES = 'PICTURE_UPDATE_FILTER_TYPES';
export const UPDATE_NAME = 'PICTURE_UPDATE_NAME';
export const UPDATE_PROGRESS = 'PICTURE_UPDATE_PROGRESS';

interface AddCustomPicturesAction {
    type: typeof ADD_CUSTOM_PICTURES,
    payload: Picture[]
}

interface AddFilesAction {
    type: typeof ADD_FILES,
    payload: any[]
}

interface AddSelectionAction {
    type: typeof ADD_SELECTION,
    payload: {
        picture: Picture,
        name: null | string
    }
}

interface DeleteFileAction {
    type: typeof DELETE_FILE,
    payload: number
}

interface DeleteSelectionAction {
    type: typeof DELETE_SELECTION,
    payload: number
}

interface ResetCreateFormAction {
    type: typeof RESET_CREATE_FORM
}

interface SetAddingAction {
    type: typeof SET_ADDING,
    payload: boolean
}

interface SetCreateAltAction {
    type: typeof SET_CREATE_ALT,
    payload: string | null
}

interface SetCreateCreditAction {
    type: typeof SET_CREATE_CREDIT,
    payload: string | null
}

interface SetCreateDescriptionAction {
    type: typeof SET_CREATE_DESCRIPTION,
    payload: string | null
}

interface SetCreateNameAction {
    type: typeof SET_CREATE_NAME,
    payload: string | null
}

interface SetCreateTypeAction {
    type: typeof SET_CREATE_TYPE,
    payload: PictureType
}

interface SetCropperAction {
    type: typeof SET_CROPPER,
    payload: any
}

interface SetLimitAction {
    type: typeof SET_LIMIT,
    payload: number
}

interface SetListAction {
    type: typeof SET_LIST,
    payload: List
}

interface SetPageAction {
    type: typeof SET_PAGE,
    payload: number
}

interface SetPictureAction {
    type: typeof SET_PICTURE,
    payload: boolean | Picture
}

interface SetQueryAction {
    type: typeof SET_QUERY,
    payload: {
        key: string,
        query: unknown
    }
}

interface SetRunningAction {
    type: typeof SET_RUNNING,
    payload: boolean
}

interface SetSearchAction {
    type: typeof SET_SEARCH,
    payload: string
}

interface ToggleCreateAction {
    type: typeof TOGGLE_CREATE
}

interface ToggleFilterAction {
    type: typeof TOGGLE_FILTER
}

interface ToggleKeepAction {
    type: typeof TOGGLE_KEEP
}

interface ToggleRefresh {
    type: typeof TOGGLE_REFRESH
}

interface UpdateAltAction {
    type: typeof UPDATE_ALT,
    payload: string
}

interface UpdateCreateTabAction {
    type: typeof UPDATE_CREATE_TAB,
    payload: 0 | 1
}

interface UpdateCreditsAction {
    type: typeof UPDATE_CREDITS,
    payload: string
}

interface UpdateDescriptionAction {
    type: typeof UPDATE_DESCRIPTION,
    payload: string
}

interface UpdateFilterTypesAction {
    type: typeof UPDATE_FILTER_TYPES,
    payload: PictureType[]
}

interface UpdateNameAction {
    type: typeof UPDATE_NAME,
    payload: string
}

interface UpdateProgressAction {
    type: typeof UPDATE_PROGRESS,
    payload: number
}

export type PictureActionTypes =
    AddCustomPicturesAction |
    AddFilesAction |
    AddSelectionAction |
    DeleteFileAction |
    DeleteSelectionAction |
    ResetCreateFormAction |
    SetAddingAction |
    SetCreateAltAction |
    SetCreateCreditAction |
    SetCreateDescriptionAction |
    SetCreateNameAction |
    SetCreateTypeAction |
    SetCropperAction |
    SetLimitAction |
    SetListAction |
    SetPageAction |
    SetPictureAction |
    SetQueryAction |
    SetRunningAction |
    SetSearchAction |
    ToggleCreateAction |
    ToggleFilterAction |
    ToggleKeepAction |
    ToggleRefresh |
    UpdateAltAction |
    UpdateCreateTabAction |
    UpdateCreditsAction |
    UpdateDescriptionAction |
    UpdateFilterTypesAction |
    UpdateNameAction |
    UpdateProgressAction;
