import { isNumber } from "lodash";

const initial_state = {
    loaded: false,
    trip_id: null,
    trip_reference: 0,
    start_date: null,
    end_date: null,
    budget: 0,
    travelers: [],
    traveler_groups: [],
    providers: [],
    manual_providers: [],
    currency: {
        id: 47,
        symbol: "€",
        iso_code: "EUR",
        international_name: "Euro",
        name: "Euro",
        usd_factor: 0.81370100
    },
    currencies: null,
    contact_traveler: null,
    contact_traveler_detail: null,
    data_trip: null,
    all_data: null,
    manual_cart: false,
    total_cost: 0,
    invoices: null,
    product_period: [],
    prices_terrestrial: [],
    prices_flight: [],
    tripIsLoaded: false
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.trip !== undefined) {
                Object.keys(action.payload.trip).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.trip[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "MATERIAL_UPDATE_PARAMETERS": {
            const { current_groups } = action.payload;
            let traveler_groups = new_state.traveler_groups.slice();
            current_groups.map((current_group) => {
                let has_group = false;
                traveler_groups.map((traveler_group) => {
                    if (current_group.id === traveler_group.id) {
                        has_group = true;
                    }
                });
                if (!has_group) {
                    traveler_groups.push(current_group);
                }
            });
            new_state.traveler_groups = traveler_groups;
            return new_state;
        }

        case 'CART_REFRESH_PRICE': {
            const stackInfoIds = Object.keys(action.payload?.packages ?? {}).map((key) => {
                return parseInt(key);
            }) ?? [];
            const stackNumbers = stackInfoIds.map((stackInfoId) => {
                const stackNumber = state.data_trip?.stack_info?.find((stackInfo) => {
                    return stackInfo.id === stackInfoId;
                })?.stack_number;
                return {
                    stackNumber,
                    stackInfoId
                };
            }).filter((item) => isNumber(item.stackNumber));

            if (new_state.data_trip) {
                new_state.data_trip = { ...new_state.data_trip };
            }
            for (const { stackNumber, stackInfoId } of stackNumbers) {
                new_state.data_trip.prices_terrestrial = new_state.data_trip.prices_terrestrial?.filter((price) => {
                    return price.stack_number !== stackNumber;
                });
                new_state.data_trip.prices_terrestrial = new_state.data_trip.prices_terrestrial?.concat(
                    action.payload.packages[stackInfoId]?.prices_terrestrial ?? []
                );
            }
            break;
        }
        case "TRIP_IS_LOADED": {
            new_state.tripIsLoaded = true;
            return new_state;
        }
        case "TRIP_SET_ALL_DATA": {
            new_state.all_data = action.payload.data;
            return new_state;
        }
        case "TRIP_SET_DATA": {
            new_state.data_trip = action.payload.data_trip;
            new_state.travelers = action.payload.travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            new_state.budget = action.payload.budget;
            new_state.currency = action.payload.currency;
            new_state.contact_traveler = action.payload.contact_traveler;
            new_state.contact_traveler_detail = action.payload.contact_traveler_detail;
            new_state.trip_reference = action.payload.trip_reference;
            new_state.manual_cart = (action.payload.data_trip.prices_flight.length > 0 || action.payload.data_trip.prices_terrestrial.length > 0);
            return new_state;
        }
        case "TRIP_SET_DATA_TRIP": {
            new_state.data_trip = action.payload.data_trip;
            if (action.payload.data_trip !== undefined && action.payload.data_trip !== null) {
                new_state.manual_cart = (action.payload.data_trip.prices_flight.length > 0 || action.payload.data_trip.prices_terrestrial.length > 0);
            }
            return new_state;
        }
        case "TRIP_EDIT_MARGIN_FLIGHT": {
            let data_trip = Object.assign({}, new_state.data_trip);
            let data = Object.assign({}, data_trip.flight);
            data.edit_margin = action.payload.flag;
            data_trip.flight = Object.assign({}, data);
            new_state.data_trip = Object.assign({}, data_trip);
            return new_state;
        }
        case "TRIP_EDIT_MARGIN_TERRESTRIAL": {
            let data_trip = Object.assign({}, new_state.data_trip);
            let data = Object.assign({}, data_trip.terrestrial);
            data.edit_margin = action.payload.flag;
            data_trip.terrestrial = Object.assign({}, data);
            new_state.data_trip = Object.assign({}, data_trip);
            return new_state;
        }
        case "TRIP_SET_CONTACT_TRAVELER": {
            new_state.contact_traveler = action.payload.contact_traveler;
            new_state.contact_traveler_detail = action.payload.contact_traveler_detail;
            return new_state;
        }
        case "TRIP_EDIT_TRAVELERS": {
            let traveler_arr = new_state.travelers.slice();
            traveler_arr[action.payload.index] = action.payload.traveler;
            new_state.travelers = traveler_arr.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_SET_CURRENCY": {
            new_state.currency = action.payload.currency;
            return new_state;
        }
        case "TRIP_SET_CURRENCIES": {
            new_state.currencies = action.payload.currencies;
            return new_state;
        }
        case "TRIP_SET_BUDGET": {
            new_state.budget = action.payload.budget;
            return new_state;
        }
        case "TRIP_SET_DATES": {
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            return new_state;
        }
        case "TRIP_SET_START_DATE": {
            new_state.start_date = action.payload.start_date;
            return new_state;
        }
        case "TRIP_SET_END_DATE": {
            new_state.end_date = action.payload.end_date;
            return new_state;
        }
        case "TRIP_SET_ID": {
            new_state.loaded = true;
            new_state.trip_id = action.payload.trip_id;
            return new_state;
        }
        case "TRIP_SET_INVOICES": {
            new_state.invoices = action.payload;
            return new_state;
        }
        case "TRIP_SET_TRAVELERS": {
            new_state.travelers = action.payload.travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_ADD_TRAVELERS": {
            let copy_travelers = new_state.travelers.slice();
            copy_travelers = [].concat(copy_travelers, action.payload.traveler);
            new_state.travelers = copy_travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_EDIT_TRAVELER_GROUPS": {
            let tmp_traveler_groups = new_state.traveler_groups.slice();
            tmp_traveler_groups[action.payload.index] = action.payload.traveler_group;
            new_state.traveler_groups = tmp_traveler_groups;
            return new_state;
        }
        case "TRIP_REMOVE_TRAVELER": {
            let to_remove = action.payload.traveler;
            let copy_travelers = new_state.travelers.slice();
            for (let i = copy_travelers.length - 1; i >= 0; i--) {
                if (to_remove === 2) {//children
                    if (copy_travelers[i].flight_type === "CNN" || copy_travelers[i].flight_type === "INF") {
                        copy_travelers.splice(i, 1);
                        new_state.travelers = copy_travelers.sort((a, b) => {
                            return (a.id - b.id);
                        });
                        return new_state;
                    }
                } else if (copy_travelers[i].flight_type === "ADT") {
                    copy_travelers.splice(i, 1);
                    new_state.travelers = copy_travelers.sort((a, b) => {
                        return (a.id - b.id);
                    });
                    return new_state;
                }
            }
            new_state.travelers = copy_travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_ADD_TRAVELER_GROUPS": {
            let copy_travelers = new_state.traveler_groups.slice();
            copy_travelers = [].concat(copy_travelers, action.payload.traveler_group);
            new_state.traveler_groups = copy_travelers;
            return new_state;
        }
        case "TRIP_SET_TRAVELER_GROUPS": {
            new_state.traveler_groups = action.payload.traveler_groups.toSorted((a, b) => a.id - b.id);
            return new_state;
        }
        case "TRIP_SET_PROVIDERS": {
            new_state.providers = action.payload.providers;
            if (new_state.manual_providers.length > 0) {
                new_state.providers.map((connected) => {
                    new_state.manual_providers.map((manual) => {
                        if (connected.provider.id === manual.provider.id) {
                            connected.is_manual = true;
                            manual.is_xml = true;
                        }
                    });
                });
            }
            new_state.providers.sort(function (a, b) {
                return a.provider.name.localeCompare(b.provider.name);
            });
            return new_state;
        }
        case "TRIP_SET_MANUAL_PROVIDERS": {
            new_state.manual_providers = action.payload.manual_providers;
            if (new_state.providers.length > 0) {
                new_state.providers.map((connected) => {
                    new_state.manual_providers.map((manual) => {
                        if (connected.provider.id === manual.provider.id) {
                            connected.is_manual = true;
                            manual.is_xml = true;
                        }
                    });
                });
            }
            new_state.manual_providers.sort(function (a, b) {
                return a.provider.name.localeCompare(b.provider.name);
            });
            return new_state;
        }
        case "TRIP_SET_TRAVELER_ID": {
            let travelers = new_state.travelers.slice();
            let copy_travelers = [];
            travelers.map((traveler, index_traveler) => {
                if (index_traveler === action.payload.traveler_id) {
                    let copy_traveler = Object.assign({}, traveler);
                    copy_traveler.passenger_group = action.payload.index;
                    traveler = copy_traveler;
                }
                copy_travelers.push(traveler);
            });
            new_state.travelers = copy_travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_RESET_TRAVELERS" : {
            let travelers = new_state.travelers.slice();
            let copy_travelers = [];
            travelers.map((traveler) => {
                let copy_traveler = Object.assign({}, traveler);
                copy_traveler.passenger_group = null;
                traveler = copy_traveler;
                copy_travelers.push(traveler);
            });
            new_state.travelers = copy_travelers.sort((a, b) => {
                return (a.id - b.id);
            });
            return new_state;
        }
        case "TRIP_SET_TOTAL_COST": {
            new_state.total_cost = action.payload;
            return new_state;
        }
        case "TRIP_UPDATE_INVOICE": {
            new_state.invoices = new_state.invoices.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            return new_state;
        }
        case "TRIP_SET_CIRCUIT_INFO": {
            return{...new_state, product_period: action.payload.product_period, prices_terrestrial: action.payload.prices_terrestrial, prices_flight: action.payload.prices_flight}
        }
    }
    return new_state;
}
